@import "../node_modules/react-calendar/dist/Calendar.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image_class {
  height: 70px;
  width: 70px;
  padding: 5px;
  object-fit: contain;
}

.add_offer_button {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.add_offer_button button {
  border-radius: 5px;
  background-color: rgb(41, 48, 67);
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.modaloverlay:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.delete_container {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(12, 3, 3, 0.5);
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  z-index: -1;
  display: none;
}

.showModal {
  display: block !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 99999 !important;
}

.close_modal {
  text-align: end;
  margin-left: -8px;
  margin-top: 4px;
}

.main-sidebar {

  bottom: 0 !important;

  width: 250px;
  border: none !important;
  position: relative !important;
  height: 100vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #293043 !important;
  /* padding-right: 1rem; */
  padding-bottom: 2rem;

}

.main-sidebar .mainmenu {
  padding: 0 20px;
}

.main-sidebar::-webkit-scrollbar {
  width: 10px;
}


.main-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.main-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: 100% !important;
}

.mainmenu a {
  display: block;
  /* border: 0.5px solid #6E7781; */
  /* background-color: #293043; */
  text-decoration: none;
  /* padding: 10px; */
  color: rgba(255, 255, 255, 0.5);
  /* font-size: 12px; */
  text-align: justify;
  padding: 1px;
  font-size: 10px;
  font-weight: 500;
  /* font-family:; */
  font-style: normal;
  /* border-radius: 1px solid blue ; */

}

.mainmenu li ul li {
  margin-top: 2px;
  border: 0.5px solid #6E7781;
  padding: 0 5px;
  border-radius: 3px;
}

.mainmenu li ul li link i {
  margin-right: 12px;
}

/* add hover behaviour */
.mainmenu li ul li:hover {

  border: 0.5px solid #4AA081;

}

.mainmenu li ul li a:hover {

  color: white;

}

.mainmenu li:hover .submenu {
  display: block;
  max-height: 250px;
}

.mainmenu li ul li.active {
  background-color: #4AA081;

}

.mainmenu li ul li.active a {
  color: white;
  font-weight: 600;
}

.edit_Modal {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(12, 3, 3, 0.5);
}

.form_control input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* akshays code */
.note-toolbar {
  text-align: left !important;
  background: white !important;
}

.note-btn-group .btn {
  background-color: #999999 !important;
  margin-top: 0px;
}

.summernote {

  position: rel;
  display: flex;
  /* align-items: flex-end; */
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  /* color: whitesmoke; */
  background-color: #ffffffdd;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */

}

.note-editor.card:after {
  content: "";
  /* display: block; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  /* pointer-events: none; */
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
  /* transform: translateY(-50%); */
  /* transition: transform calc(var(--d) * 2) var(--e); */
}

.note-editor .note-airframe .card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  color: #000 !important;
  background: #f00b0b !important;

  /* color: whitesmoke; */
  /* background-color: whitesmoke; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}

.note-editor.note-frame {
  color: #ffffff !important;
  background-color: white !important;
}



.note-editing-area.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  /* color: whitesmoke; */
  background-color: whitesmoke;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}

.note-editing-area {
  width: 100%;
  /* background:white !important;; */
}

.note-editing-area .card {
  align-items: left;

}

.note-editor.note-frame {
  color: black !important;
  /* background-color: white!important; */
}


.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

.note-btn-group.note-insert {
  display: none;
}

.note-editable {
  text-align: left;

}

.note-editor.card {
  background: #ffff !important;
}


/* Sumit css */

.main_heading_h1 {
  color: #000;
  font-weight: 600;
  font-family: Poppins;
  font-size: 10px;
  padding: 5px 20px;
}

.box_padding {
  padding: 10px 15px !important;
  margin-top: 10px !important;
}

.border_class {
  /* border: 1px solid #D9D9D9; */
  border-radius: 5px;
  background: #ffffff;
  padding: 10px 15px !important;
  margin-top: 10px !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}

.border_class2 {
  /* border: 1px solid #D9D9D9; */
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}

.publish_button {
  font-weight: 500;
  border: 0 solid #000;
  color: #fff;
  border-radius: 2px;
  margin-left: 8px;
  background-color: #000 !important;
  font-size: 10px;
  width: 118px;
  height: 28px;
  border-radius: 3px;
}

.delete_image {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.preview_image {
  width: 16px;
  height: 16px;
  cursor: pointer;
  cursor: pointer;
}

.preview_button {
  margin: 0 13px 0 18px;
  border: .5px solid #000;
  border-radius: 2px;
  width: 118px;
  align-items: center;
  display: flex;
  padding: 0 10px;
  height: 28px;
}

.news_bar {
  transform: scaleY(2.3);
  color: #4AA081;
  margin-left: 13px;

}

.preview_button_p_tag {
  font-weight: 600;
  font-size: 12px;
  display: contents;
}

.create_button {
  border: 0 solid #000;
  padding: 6px 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  background: #000;
  flex-wrap: wrap;
  border-radius: 3px;
  height: auto;
  margin: 0;
  color: #fff;

}

.save_button {
  font-weight: bold;
  border-radius: 5px;
  color: rgb(31, 57, 119);
  /* margin-left: auto; */
  background-color: white;
  font-size: 12px;
  border: 1px solid rgb(31, 57, 119);
  width: 120px;
}

.left_padding {
  padding-left: 15px;
}

.offer_description_model {
  width: 335px;
  height: 100px;
  border: 1px solid;
  overflow: auto;
}

.offer_description_modal {
  background: rgba(243, 242, 239, 0.60);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  /* left: 46%;
      top: 23%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
}

.input_box {
  color: black;
	width: 100% !important;
	height: 28px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	font-size: 10px !important;
	padding: 5px;
	border-radius: 5px !important;
	outline: none !important;
}

.hbotQl {
  font-weight: 600 !important;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked+label:before {
  background-color: #15a312;
  z-index: 2;
}

input[type="radio"]+label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 7px;
  margin-right: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.radio-custom:checked~label {
  color: black !important;
  font-weight: bold !important;
}

.create_button {
  border: 0px solid rgb(0, 0, 0);
  padding: 6px 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  flex-wrap: wrap;
  border-radius: 5px;
  height: auto;
  margin: 0px;
  color: #ffffff;
}

.create_button_inner {
  font-size: 10px;
    font-weight: 500;
    font-family: Poppins;
}

.create_button_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.search_box_div {
  height: 27px;
  background: white;
  border: 1px solid lightgrey;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 8px;
  border-radius: 20px;

}

.search_box_img {
  width: 15px;
  height: 15px;
}

.search_box {
  border: none !important;
  background: white;
  height: 23px !important;
  width: 100% !important;
  font-weight: 500;
  font-size: 10px;
  border-radius: 20px !important;
}

.search_box_padding {
  padding: 10px 0px !important;
  margin: 10px 0px !important;
}
.search_box::placeholder {
	color: black !important;
	font-size: 9PX !important;
  font-weight: 500;

}
.search_box_border_class {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #ffffff;
  padding: 7px 0px !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}
.polls_heading_h1 {
  color: black;
  font-weight: 600;
  font-family: Poppins;
  font-size: 11px;
  line-height: 24px;
  margin-left: 15px;
}
.steps_main_div {
  padding: 5px;
  font-size: 10px;
    font-weight: 600;
    background: rgba(110, 119, 129, 0.1);
}
.steps_main_div_p {
  color: rgb(31, 57, 119);
  padding-left: 15px;
  margin-bottom: 0px;
}
.steps_main_div_inner_p {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
  font-weight: 700;
  margin-bottom: 0px;
}
.all_labels {
  /* color: rgb(31, 57, 119); */
  color: #1F3977;
  font-size: 9px;
	font-weight: 600 !important;
  margin: 0px;
  margin-bottom: 5px;
}


.all_inputs {
  color: black;
	width: 100% !important;
	height: 28px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	font-size: 10px !important;
	padding: 5px;
	border-radius: 5px !important;
	outline: none !important;

}
.all_inputs::placeholder {
	font-size: 9PX !important;
}
.input_box::placeholder {
	font-size: 9PX !important;
}
.all_inputs_discount::placeholder {
	font-size: 9PX !important;
}

.input_box:hover, .all_inputs:hover, .note-editable:hover{
	border: 1px solid blue;
}
.input_box:disabled {
  background-color: #f0f0f0;
  color: grey;
}

.all_stars {
  color: rgb(235, 36, 36);
  font-weight: 600;
  margin: 0px;
  margin-left: 3px;

  font-size: 11px;
}

.all_validations_h4 {
  color: red;
  font-size: 10px;
  margin-left: 0px;
}

.next_step_btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.01);
  height: 28px;
  width: 130px;
  font-size: 10px;
  margin-left: 15px;
  /* padding: 1px 10px; */
}

.next_step_btn_div {
  margin-left: 5px;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
}

.next_step_btn_img {
  width: 15px;
  height: 15px;
  margin-left: auto;

}

.buttons_div {
  align-items: center;
  justify-content: end;
  /* padding-right: 30px !important; */
}

.preview_img {
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
}

.preview_img_div {
  display: flex;
  width: 100%;
  justify-content: end;
}

.preview_font {
  font-weight: 500;
  font-size: 10px;
  color: #525252;
  display: inline-flex;

}

.preview_polls {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.preview_polls_inner_div1 {
  padding: 15px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 420px;
  height: 100%;
}

.preview_polls_inner_div2 {
  background: white;
  margin-top: 10px;
  padding: 5px 10px;
  /* border: 0.4px solid rgb(196, 196, 196); */
  overflow: auto;
  height: 565px;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)
}

.preview_polls_inner_label {
  border-bottom: 2px solid rgb(21, 163, 18);
  transform: rotate(0.13deg);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
}

.preview_polls_inner_div2::-webkit-scrollbar {
  width: 4px;
}


.preview_polls_inner_div2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.preview_polls_inner_div2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.preview_category {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_inner {
  padding: 10px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 420px;
  height: 100%;
}

.edit_all_labels {
  /* color: rgb(31, 57, 119); */
  color: #1F3977;
  font-size: 11px;
  font-weight: 600 !important;
  margin: 0px;
  margin-bottom: 5px;
}

.edit_all_inputs {
  color: black;
  width: 100% !important;
  height: 30px;
  /* border: 1px solid rgb(196, 196, 196); */
  border: 1px solid #ced4da;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px !important;

}

.edit_inner_div {
  border-bottom: 2px solid rgb(21, 163, 18);
  transform: rotate(0.13deg);
  padding-bottom: 5px;
  align-items: center;
}

.main_labels {
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
}

.cancel_img {
  cursor: pointer;
  height: 30px;
  width: 20px;
}

.edit_container {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_container_inner {
  padding: 13px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 400px;
  height: 100%;
}

.all_edit_inputs {
  color: black;
  width: 100% !important;
  height: 30px;
  border: 1px solid rgb(196, 196, 196);
  box-sizing: border-box;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px !important;

}

.edit_row_padding2 {

  padding: 10px 0px !important;
  margin-top: 5px !important;
}

.edit_buttons_div {
  padding: 7px;
  gap: 25px;
  align-items: center;

}

.edit_row_margin {
  margin: 0px !important;
}

.edit_cancel_button {
  border: none;
  color: rgb(31, 57, 119);
  background: transparent;
  font-weight: 600;
  font-size: 13px;
}

.edit_update_button {
  background-color: rgb(0, 0, 0);
  border-radius: 3px;
  padding: 7px 0px;
  color: #ffffff;
  width: 130px;
  font-weight: 600;
  font-size: 13px;
  border: none;
}


.preview_form {
  overflow-y: auto;
  /* height: 565px; */

  margin-top: 10px;
  max-height: 565px;
}

.preview_form::-webkit-scrollbar {
  width: 7px;
}

.preview_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;

}

.preview_form::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.preview_row {
  background: rgb(228, 233, 243);
  padding: 7px;
  margin: 7px 3px 0px !important;
}

.preview_row_inner {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 10px;
}

.edit_img {
  height: 30px;
  width: 21px;
  cursor: pointer;
  margin-left: auto;
}

.preview_h4 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: 600;
}

.add_faq_button {

  font-weight: 600;
  margin: 0px;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;

  height: 35px;
  font-family: Poppins;

  color: #000000;
  width: auto;
  border: 1px solid;

}

.edit_campus_modal {
  z-index: 1;
  border-radius: 5px;
  width: 180px;
  padding: 2px 10px 10px 10px;
  top: 30px;
  right: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #C4C4C4;
  ;
}

.hover_class {
  background: #6E77811A;
  transition: all 0.2s ease-in-out;
  padding: 2px 10px;
  margin-top: 5px;
}

.hover_class:hover {
  background: #D9D9D9;
  cursor: pointer;

}

.campus_img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.campus_inner_div {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
  display: flex;
}

.add_faq_button {

  font-weight: 600;
  margin: 0px;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;

  height: 35px;
  font-family: Poppins;

  color: #000000;
  width: auto;
  border: 1px solid;

}

.add_students_modal {

  border-radius: 5px;
  width: 180px;
  padding: 4px 0px 4px 0px;
  top: 60px;
  right: 29px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #b9b2b2;
  ;
}

.add_students_button {
  width: 100%;
  border: none;
  padding: 2px 8px;
  background: white;
}

.hover_class {
  /* background: #ffffff; */
  transition: all 0.3s ease-in-out;
}

.hover_class:hover {
  background: #D9D9D9;

}

.add_student_list_modal {
  background: white;
  width: 373px;
  height: auto;

}

.student_add_new_modal {
  /* background: rgba(0, 0, 0, 0.8); */
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  /* -webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in; */
  /* z-index: -1; */
  display: none;

}

.student_add_new_modal:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
}

.delete_img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.success_msg {
  display: none;
  margin-top: 5px;
  width: 100%;
}

.faq_bar {
  transform: scaleY(2.8);
  color: #4AA081;
  margin-left: 13px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 12px;
  height: 12px;
}

.icon_holidayList {
  width: 12px;
  height: 12px;
}
.event_inner_div{
  max-height: 400px;
  min-height: 130px;
  overflow: auto;
  padding-bottom: 10px;
}

.event_inner_div::-webkit-scrollbar {
  width: 7px;
}

.event_inner_div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;

}

.event_inner_div::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}
.info_bar{
  transform: scaleY(1.3);
  color: #4AA081;
  margin: 0px 15px;

}
.eVPPvF{
  /* padding-left: 0px !important; */
}
.cfIiYD div:first-child{
  /* padding-left: 16px !important; */
}
.dApqnJ{
  display: flex !important;
}