@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


/************ Start Main Rules **************/
*{
  /* margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box; */
/*   font-family: 'Roboto', sans-serif; */
    /* font-family: 'Raleway', sans-serif; */
}
.active{
  color: white;
}

/* body{background: #191919  } */

.main-box{
  position: fixed;
  display:flex;
  flex-wrap: wrap !important;
  width: 100%;

  height: 100% !important;
 overflow-x: auto;

}

.lined-link{
float:right;
margin-right: auto;
  text-decoration: none;
  transition: all 0.2s linear;
  /* margin-left: 200px !important; */

}

.lined-link:hover{
 color: rgb(192, 189, 189);
  text-decoration: underline;
}



/************ Start Slider Container **************/
.slider-cont
{
  width: auto !important;
  padding:10px;
  position:fixed;
  height: 100% !important;
  float: left;
  margin: 0px !important;
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */

  background-color: #1F3977;
}
.form-cont
{
  width: 100%;
  height: 100% !important;
 /* margin-left: 50%; */
  /* position:absolute; */
  /* float: right; */
  /* margin: 0px !important; */
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */
  background-color: transparent;


}
.login_image
{
  background-image: url(./Images/admin_login.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;

  width: 60%;
  height: 60%;
}
/* .unicircle_img
{
  background-image: url(./Images/image.png);

  background-repeat: no-repeat;
  background-size: cover;
 margin-left: auto;

  width: 83px;
  height: 102px;
} */
.slick-slide,
.slick-slider{
  width: 100%;
  height: 100%
}

.clear-fix{
  clear: both;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: #8dffea;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active button{
  background-color: #53C5B9;
  transform: scale(1.6);
  box-shadow: 0 0 10px #1b1b1b
}

.img-txt{
  position: relative;
}

.img-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(47, 63, 85, 0.53);
}

.img-txt h1{
  position: absolute;
  display: block;
  top: 50%;
  color: #fff;
  width: 100%;
  padding: 50px 0;
  font-size: 45px;
  font-weight: 100;
  text-align: center;
  transform: translateY(-50%);
  background-color: transparent;
}

/************ Start Form Container **************/

.top-buttons {
  position: relative;
  display: block;
  width: 100%;
  height: 15%;
  padding: 25px;
  text-align: right;
  float: right;
}

.top-buttons button {
  /* color: #cacaca; */
  border: none;
  width: 90px;
  margin: -2px;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  background:#e44c7a;
  color: #fff !important;
  cursor: pointer;
}

.top-buttons button:nth-child(1) {
  border-radius: 50px 0 0 50px;
}

.top-buttons button:nth-child(2) {
  border-radius: 0 50px 50px 0;
}

.top-active-button {
  color: rgb(223, 219, 219) !important;
  /* box-shadow: 0 0 20px #2a2a2a; */
  background: rgb(223, 219, 219) !important;
  transition: all 0.3s ease;

}

.form{
  width: 100%;
  height: 85%;
  padding: 10px 40px;
}

.form form {
  width: 100%;
  height: 100%;
}

.form form
lable,
input[type="text"],
input[type="email"],
input[type="password"] {
  display: block;
}

.form form lable {
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 6px 0;
}

/* .nav-item i{
  color: white !important;
  font-size: 15px;
  padding-left: 0px !important;

} */

.nav-item p:hover, .nav-item i:hover{
  border: none !important;
  color: white !important;

}

.form form
input[type="text"],
input[type="email"],
input[type="password"] {
  /* color: black; */
  /* width: 80%; */
  /* border: none; */
  /* font-size: 16px; */
  /* font-weight: 200; */
  /* margin-left: 45px; */
  /* margin-bottom: 30px; */
  /* padding: 5px 0 10px 0; */
  /* background: white; */
  /* border-left: 6px solid #e44c7a; */
}

.form form input::placeholder{
  color: #9E9E9E !important;
  padding-left: 8px;
  font-weight: bold;
}

.form form a {
  color: #fff;
}

input.form-btn {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 30px;
  /* margin-left: 10px; */
  cursor: pointer;
  /* border-radius: 35px; */
  transition: all 0.3s linear;
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */
  border:1px solid #e0396b;
  background: transparent;
  color:#e0396b;
}

input.form-btn:hover{
  border:1px solid  #e0396b;
  background: white;
  color:#e0396b;

}

.form-signin{
  display: none;
  padding-top: 20px;
}


/* calender */
