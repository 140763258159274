@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,500,400);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,700);
@import url(https://weloveiconfonts.com/api/?family=entypo|fontawesome|zocial);
@import url(https://fonts.googleapis.com/css?family=Open+sans);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap);
@import url(https://use.fontawesome.com/releases/v5.0.6/css/all.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image_class {
  height: 70px;
  width: 70px;
  padding: 5px;
  object-fit: contain;
}

.add_offer_button {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.add_offer_button button {
  border-radius: 5px;
  background-color: rgb(41, 48, 67);
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.modaloverlay:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.delete_container {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(12, 3, 3, 0.5);
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  z-index: -1;
  display: none;
}

.showModal {
  display: block !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 99999 !important;
}

.close_modal {
  text-align: end;
  margin-left: -8px;
  margin-top: 4px;
}

.main-sidebar {

  bottom: 0 !important;

  width: 250px;
  border: none !important;
  position: relative !important;
  height: 100vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #293043 !important;
  /* padding-right: 1rem; */
  padding-bottom: 2rem;

}

.main-sidebar .mainmenu {
  padding: 0 20px;
}

.main-sidebar::-webkit-scrollbar {
  width: 10px;
}


.main-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.main-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: 100% !important;
}

.mainmenu a {
  display: block;
  /* border: 0.5px solid #6E7781; */
  /* background-color: #293043; */
  text-decoration: none;
  /* padding: 10px; */
  color: rgba(255, 255, 255, 0.5);
  /* font-size: 12px; */
  text-align: justify;
  padding: 1px;
  font-size: 10px;
  font-weight: 500;
  /* font-family:; */
  font-style: normal;
  /* border-radius: 1px solid blue ; */

}

.mainmenu li ul li {
  margin-top: 2px;
  border: 0.5px solid #6E7781;
  padding: 0 5px;
  border-radius: 3px;
}

.mainmenu li ul li link i {
  margin-right: 12px;
}

/* add hover behaviour */
.mainmenu li ul li:hover {

  border: 0.5px solid #4AA081;

}

.mainmenu li ul li a:hover {

  color: white;

}

.mainmenu li:hover .submenu {
  display: block;
  max-height: 250px;
}

.mainmenu li ul li.active {
  background-color: #4AA081;

}

.mainmenu li ul li.active a {
  color: white;
  font-weight: 600;
}

.edit_Modal {
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(12, 3, 3, 0.5);
}

.form_control input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* akshays code */
.note-toolbar {
  text-align: left !important;
  background: white !important;
}

.note-btn-group .btn {
  background-color: #999999 !important;
  margin-top: 0px;
}

.summernote {

  position: rel;
  display: flex;
  /* align-items: flex-end; */
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  /* color: whitesmoke; */
  background-color: #ffffffdd;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */

}

.note-editor.card:after {
  content: "";
  /* display: block; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  /* pointer-events: none; */
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
  /* transform: translateY(-50%); */
  /* transition: transform calc(var(--d) * 2) var(--e); */
}

.note-editor .note-airframe .card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  color: #000 !important;
  background: #f00b0b !important;

  /* color: whitesmoke; */
  /* background-color: whitesmoke; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}

.note-editor.note-frame {
  color: #ffffff !important;
  background-color: white !important;
}



.note-editing-area.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  /* color: whitesmoke; */
  background-color: whitesmoke;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
}

.note-editing-area {
  width: 100%;
  /* background:white !important;; */
}

.note-editing-area .card {
  align-items: left;

}

.note-editor.note-frame {
  color: black !important;
  /* background-color: white!important; */
}


.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

.note-btn-group.note-insert {
  display: none;
}

.note-editable {
  text-align: left;

}

.note-editor.card {
  background: #ffff !important;
}


/* Sumit css */

.main_heading_h1 {
  color: #000;
  font-weight: 600;
  font-family: Poppins;
  font-size: 10px;
  padding: 5px 20px;
}

.box_padding {
  padding: 10px 15px !important;
  margin-top: 10px !important;
}

.border_class {
  /* border: 1px solid #D9D9D9; */
  border-radius: 5px;
  background: #ffffff;
  padding: 10px 15px !important;
  margin-top: 10px !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}

.border_class2 {
  /* border: 1px solid #D9D9D9; */
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}

.publish_button {
  font-weight: 500;
  border: 0 solid #000;
  color: #fff;
  border-radius: 2px;
  margin-left: 8px;
  background-color: #000 !important;
  font-size: 10px;
  width: 118px;
  height: 28px;
  border-radius: 3px;
}

.delete_image {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.preview_image {
  width: 16px;
  height: 16px;
  cursor: pointer;
  cursor: pointer;
}

.preview_button {
  margin: 0 13px 0 18px;
  border: .5px solid #000;
  border-radius: 2px;
  width: 118px;
  align-items: center;
  display: flex;
  padding: 0 10px;
  height: 28px;
}

.news_bar {
  transform: scaleY(2.3);
  color: #4AA081;
  margin-left: 13px;

}

.preview_button_p_tag {
  font-weight: 600;
  font-size: 12px;
  display: contents;
}

.create_button {
  border: 0 solid #000;
  padding: 6px 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  background: #000;
  flex-wrap: wrap;
  border-radius: 3px;
  height: auto;
  margin: 0;
  color: #fff;

}

.save_button {
  font-weight: bold;
  border-radius: 5px;
  color: rgb(31, 57, 119);
  /* margin-left: auto; */
  background-color: white;
  font-size: 12px;
  border: 1px solid rgb(31, 57, 119);
  width: 120px;
}

.left_padding {
  padding-left: 15px;
}

.offer_description_model {
  width: 335px;
  height: 100px;
  border: 1px solid;
  overflow: auto;
}

.offer_description_modal {
  background: rgba(243, 242, 239, 0.60);
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  /* left: 46%;
      top: 23%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
}

.input_box {
  color: black;
	width: 100% !important;
	height: 28px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	font-size: 10px !important;
	padding: 5px;
	border-radius: 5px !important;
	outline: none !important;
}

.hbotQl {
  font-weight: 600 !important;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked+label:before {
  background-color: #15a312;
  z-index: 2;
}

input[type="radio"]+label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 7px;
  margin-right: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.radio-custom:checked~label {
  color: black !important;
  font-weight: bold !important;
}

.create_button {
  border: 0px solid rgb(0, 0, 0);
  padding: 6px 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  flex-wrap: wrap;
  border-radius: 5px;
  height: auto;
  margin: 0px;
  color: #ffffff;
}

.create_button_inner {
  font-size: 10px;
    font-weight: 500;
    font-family: Poppins;
}

.create_button_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.search_box_div {
  height: 27px;
  background: white;
  border: 1px solid lightgrey;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 8px;
  border-radius: 20px;

}

.search_box_img {
  width: 15px;
  height: 15px;
}

.search_box {
  border: none !important;
  background: white;
  height: 23px !important;
  width: 100% !important;
  font-weight: 500;
  font-size: 10px;
  border-radius: 20px !important;
}

.search_box_padding {
  padding: 10px 0px !important;
  margin: 10px 0px !important;
}
.search_box:-ms-input-placeholder {
	color: black !important;
	font-size: 9PX !important;
  font-weight: 500;

}
.search_box::placeholder {
	color: black !important;
	font-size: 9PX !important;
  font-weight: 500;

}
.search_box_border_class {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #ffffff;
  padding: 7px 0px !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
}
.polls_heading_h1 {
  color: black;
  font-weight: 600;
  font-family: Poppins;
  font-size: 11px;
  line-height: 24px;
  margin-left: 15px;
}
.steps_main_div {
  padding: 5px;
  font-size: 10px;
    font-weight: 600;
    background: rgba(110, 119, 129, 0.1);
}
.steps_main_div_p {
  color: rgb(31, 57, 119);
  padding-left: 15px;
  margin-bottom: 0px;
}
.steps_main_div_inner_p {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
  font-weight: 700;
  margin-bottom: 0px;
}
.all_labels {
  /* color: rgb(31, 57, 119); */
  color: #1F3977;
  font-size: 9px;
	font-weight: 600 !important;
  margin: 0px;
  margin-bottom: 5px;
}


.all_inputs {
  color: black;
	width: 100% !important;
	height: 28px;
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	font-size: 10px !important;
	padding: 5px;
	border-radius: 5px !important;
	outline: none !important;

}
.all_inputs:-ms-input-placeholder {
	font-size: 9PX !important;
}
.all_inputs::placeholder {
	font-size: 9PX !important;
}
.input_box:-ms-input-placeholder {
	font-size: 9PX !important;
}
.input_box::placeholder {
	font-size: 9PX !important;
}
.all_inputs_discount:-ms-input-placeholder {
	font-size: 9PX !important;
}
.all_inputs_discount::placeholder {
	font-size: 9PX !important;
}

.input_box:hover, .all_inputs:hover, .note-editable:hover{
	border: 1px solid blue;
}
.input_box:disabled {
  background-color: #f0f0f0;
  color: grey;
}

.all_stars {
  color: rgb(235, 36, 36);
  font-weight: 600;
  margin: 0px;
  margin-left: 3px;

  font-size: 11px;
}

.all_validations_h4 {
  color: red;
  font-size: 10px;
  margin-left: 0px;
}

.next_step_btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.01);
  height: 28px;
  width: 130px;
  font-size: 10px;
  margin-left: 15px;
  /* padding: 1px 10px; */
}

.next_step_btn_div {
  margin-left: 5px;
  font-size: 10px;
  font-weight: 500;
  font-family: Poppins;
}

.next_step_btn_img {
  width: 15px;
  height: 15px;
  margin-left: auto;

}

.buttons_div {
  align-items: center;
  justify-content: end;
  /* padding-right: 30px !important; */
}

.preview_img {
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
}

.preview_img_div {
  display: flex;
  width: 100%;
  justify-content: end;
}

.preview_font {
  font-weight: 500;
  font-size: 10px;
  color: #525252;
  display: inline-flex;

}

.preview_polls {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.preview_polls_inner_div1 {
  padding: 15px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 420px;
  height: 100%;
}

.preview_polls_inner_div2 {
  background: white;
  margin-top: 10px;
  padding: 5px 10px;
  /* border: 0.4px solid rgb(196, 196, 196); */
  overflow: auto;
  height: 565px;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)
}

.preview_polls_inner_label {
  border-bottom: 2px solid rgb(21, 163, 18);
  transform: rotate(0.13deg);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
}

.preview_polls_inner_div2::-webkit-scrollbar {
  width: 4px;
}


.preview_polls_inner_div2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.preview_polls_inner_div2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.preview_category {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_inner {
  padding: 10px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 420px;
  height: 100%;
}

.edit_all_labels {
  /* color: rgb(31, 57, 119); */
  color: #1F3977;
  font-size: 11px;
  font-weight: 600 !important;
  margin: 0px;
  margin-bottom: 5px;
}

.edit_all_inputs {
  color: black;
  width: 100% !important;
  height: 30px;
  /* border: 1px solid rgb(196, 196, 196); */
  border: 1px solid #ced4da;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px !important;

}

.edit_inner_div {
  border-bottom: 2px solid rgb(21, 163, 18);
  transform: rotate(0.13deg);
  padding-bottom: 5px;
  align-items: center;
}

.main_labels {
  color: black;
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
}

.cancel_img {
  cursor: pointer;
  height: 30px;
  width: 20px;
}

.edit_container {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.edit_container_inner {
  padding: 13px;
  background: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 6px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 5px;
  width: 400px;
  height: 100%;
}

.all_edit_inputs {
  color: black;
  width: 100% !important;
  height: 30px;
  border: 1px solid rgb(196, 196, 196);
  box-sizing: border-box;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px !important;

}

.edit_row_padding2 {

  padding: 10px 0px !important;
  margin-top: 5px !important;
}

.edit_buttons_div {
  padding: 7px;
  grid-gap: 25px;
  gap: 25px;
  align-items: center;

}

.edit_row_margin {
  margin: 0px !important;
}

.edit_cancel_button {
  border: none;
  color: rgb(31, 57, 119);
  background: transparent;
  font-weight: 600;
  font-size: 13px;
}

.edit_update_button {
  background-color: rgb(0, 0, 0);
  border-radius: 3px;
  padding: 7px 0px;
  color: #ffffff;
  width: 130px;
  font-weight: 600;
  font-size: 13px;
  border: none;
}


.preview_form {
  overflow-y: auto;
  /* height: 565px; */

  margin-top: 10px;
  max-height: 565px;
}

.preview_form::-webkit-scrollbar {
  width: 7px;
}

.preview_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;

}

.preview_form::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.preview_row {
  background: rgb(228, 233, 243);
  padding: 7px;
  margin: 7px 3px 0px !important;
}

.preview_row_inner {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 10px;
}

.edit_img {
  height: 30px;
  width: 21px;
  cursor: pointer;
  margin-left: auto;
}

.preview_h4 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: 600;
}

.add_faq_button {

  font-weight: 600;
  margin: 0px;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;

  height: 35px;
  font-family: Poppins;

  color: #000000;
  width: auto;
  border: 1px solid;

}

.edit_campus_modal {
  z-index: 1;
  border-radius: 5px;
  width: 180px;
  padding: 2px 10px 10px 10px;
  top: 30px;
  right: 10px;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #C4C4C4;
  ;
}

.hover_class {
  background: #6E77811A;
  transition: all 0.2s ease-in-out;
  padding: 2px 10px;
  margin-top: 5px;
}

.hover_class:hover {
  background: #D9D9D9;
  cursor: pointer;

}

.campus_img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.campus_inner_div {
  margin-left: 15px;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
  display: flex;
}

.add_faq_button {

  font-weight: 600;
  margin: 0px;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;

  height: 35px;
  font-family: Poppins;

  color: #000000;
  width: auto;
  border: 1px solid;

}

.add_students_modal {

  border-radius: 5px;
  width: 180px;
  padding: 4px 0px 4px 0px;
  top: 60px;
  right: 29px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 1px 1px 1px 1px #b9b2b2;
  ;
}

.add_students_button {
  width: 100%;
  border: none;
  padding: 2px 8px;
  background: white;
}

.hover_class {
  /* background: #ffffff; */
  transition: all 0.3s ease-in-out;
}

.hover_class:hover {
  background: #D9D9D9;

}

.add_student_list_modal {
  background: white;
  width: 373px;
  height: auto;

}

.student_add_new_modal {
  /* background: rgba(0, 0, 0, 0.8); */
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  /* -webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in; */
  /* z-index: -1; */
  display: none;

}

.student_add_new_modal:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
}

.delete_img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.success_msg {
  display: none;
  margin-top: 5px;
  width: 100%;
}

.faq_bar {
  transform: scaleY(2.8);
  color: #4AA081;
  margin-left: 13px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 12px;
  height: 12px;
}

.icon_holidayList {
  width: 12px;
  height: 12px;
}
.event_inner_div{
  max-height: 400px;
  min-height: 130px;
  overflow: auto;
  padding-bottom: 10px;
}

.event_inner_div::-webkit-scrollbar {
  width: 7px;
}

.event_inner_div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;

}

.event_inner_div::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}
.info_bar{
  transform: scaleY(1.3);
  color: #4AA081;
  margin: 0px 15px;

}
.eVPPvF{
  /* padding-left: 0px !important; */
}
.cfIiYD div:first-child{
  /* padding-left: 16px !important; */
}
.dApqnJ{
  display: flex !important;
}
/************ Start Main Rules **************/
*{
  /* margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box; */
/*   font-family: 'Roboto', sans-serif; */
    /* font-family: 'Raleway', sans-serif; */
}
.active{
  color: white;
}

/* body{background: #191919  } */

.main-box{
  position: fixed;
  display:flex;
  flex-wrap: wrap !important;
  width: 100%;

  height: 100% !important;
 overflow-x: auto;

}

.lined-link{
float:right;
margin-right: auto;
  text-decoration: none;
  transition: all 0.2s linear;
  /* margin-left: 200px !important; */

}

.lined-link:hover{
 color: rgb(192, 189, 189);
  text-decoration: underline;
}



/************ Start Slider Container **************/
.slider-cont
{
  width: auto !important;
  padding:10px;
  position:fixed;
  height: 100% !important;
  float: left;
  margin: 0px !important;
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */

  background-color: #1F3977;
}
.form-cont
{
  width: 100%;
  height: 100% !important;
 /* margin-left: 50%; */
  /* position:absolute; */
  /* float: right; */
  /* margin: 0px !important; */
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */
  background-color: transparent;


}
.login_image
{
  background-image: url(/static/media/admin_login.9e14b8e9.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;

  width: 60%;
  height: 60%;
}
/* .unicircle_img
{
  background-image: url(./Images/image.png);

  background-repeat: no-repeat;
  background-size: cover;
 margin-left: auto;

  width: 83px;
  height: 102px;
} */
.slick-slide,
.slick-slider{
  width: 100%;
  height: 100%
}

.clear-fix{
  clear: both;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: #8dffea;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active button{
  background-color: #53C5B9;
  transform: scale(1.6);
  box-shadow: 0 0 10px #1b1b1b
}

.img-txt{
  position: relative;
}

.img-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(47, 63, 85, 0.53);
}

.img-txt h1{
  position: absolute;
  display: block;
  top: 50%;
  color: #fff;
  width: 100%;
  padding: 50px 0;
  font-size: 45px;
  font-weight: 100;
  text-align: center;
  transform: translateY(-50%);
  background-color: transparent;
}

/************ Start Form Container **************/

.top-buttons {
  position: relative;
  display: block;
  width: 100%;
  height: 15%;
  padding: 25px;
  text-align: right;
  float: right;
}

.top-buttons button {
  /* color: #cacaca; */
  border: none;
  width: 90px;
  margin: -2px;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  background:#e44c7a;
  color: #fff !important;
  cursor: pointer;
}

.top-buttons button:nth-child(1) {
  border-radius: 50px 0 0 50px;
}

.top-buttons button:nth-child(2) {
  border-radius: 0 50px 50px 0;
}

.top-active-button {
  color: rgb(223, 219, 219) !important;
  /* box-shadow: 0 0 20px #2a2a2a; */
  background: rgb(223, 219, 219) !important;
  transition: all 0.3s ease;

}

.form{
  width: 100%;
  height: 85%;
  padding: 10px 40px;
}

.form form {
  width: 100%;
  height: 100%;
}

.form form
lable,
input[type="text"],
input[type="email"],
input[type="password"] {
  display: block;
}

.form form lable {
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 6px 0;
}

/* .nav-item i{
  color: white !important;
  font-size: 15px;
  padding-left: 0px !important;

} */

.nav-item p:hover, .nav-item i:hover{
  border: none !important;
  color: white !important;

}

.form form
input[type="text"],
input[type="email"],
input[type="password"] {
  /* color: black; */
  /* width: 80%; */
  /* border: none; */
  /* font-size: 16px; */
  /* font-weight: 200; */
  /* margin-left: 45px; */
  /* margin-bottom: 30px; */
  /* padding: 5px 0 10px 0; */
  /* background: white; */
  /* border-left: 6px solid #e44c7a; */
}

.form form input:-ms-input-placeholder{
  color: #9E9E9E !important;
  padding-left: 8px;
  font-weight: bold;
}

.form form input::placeholder{
  color: #9E9E9E !important;
  padding-left: 8px;
  font-weight: bold;
}

.form form a {
  color: #fff;
}

input.form-btn {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 30px;
  /* margin-left: 10px; */
  cursor: pointer;
  /* border-radius: 35px; */
  transition: all 0.3s linear;
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.55); */
  border:1px solid #e0396b;
  background: transparent;
  color:#e0396b;
}

input.form-btn:hover{
  border:1px solid  #e0396b;
  background: white;
  color:#e0396b;

}

.form-signin{
  display: none;
  padding-top: 20px;
}


/* calender */

:root {
  --blue: #339dd8;
  /* --darkBlue: #1F3977; */
  --darkBlue: #293043;
  --yellow: #fcc10c;
  --pink: #f15cb5;
  --green: #00f3b9;
  --background: #dbdbdd;
  --color: #eff6ff;
}

body {
  margin: 0;
  padding: 0;
  /* position: fixed; */

  overflow-y: auto;
  overflow-x: hidden;
  /* height: 100% !important;
	width: auto !important; */
  background: #eff6ff;
  font-family: "Poppins", sans-serif;
}

.list p {
  float: left;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .header {
    width: 100%;
    display: inline;
  }

  .header li {
    float: none;
    display: block;
    text-align: left;
  }

  .header li {
    float: none;
    display: block;
    text-align: left;
    border: 1px solid red;
  }

  .header-right {
    float: none;
  }
}

/* student table header */
.MuiTableSortLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
  justify-content: center;
  margin-left: 35% !important;
}

/* student table header */

/* search bar */

.topnav .search-container {
  float: right;
  display: flex;
}

.topnav input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #ccc;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }

  .topnav a,
  .topnav input[type="text"],
  .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }

  .topnav input[type="text"] {
    /* border: 1px solid #ccc; */
  }
}

.switch_account:hover {
  background: rgb(241, 239, 239);
}

/* MAP */

#map {
  height: 500px;
  margin: 10px auto;
  width: 800px;
}

.pushmenu-item:hover {
  background: none !important;
}

.login-wrap {
  margin: 50px;
  height: 500px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.login-left {
  width: 60%;
  height: auto;

  background: url(/static/media/home-banner.11b11933.jpg) no-repeat;
  border-radius: 10px 0px 0px 10px;
}

.login-right {
  width: 50%;
  height: 100%;

  background-color: #a4d3e2;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px 10px 10px 0px;
}

:-ms-input-placeholder {
  color: #9e9e9e !important;
  margin: 5px 0px 0px 5px !important;
  padding-left: 3px !important;
  font-size: 13px !important;
  font-weight: 500;
}

::placeholder {
  color: #9e9e9e !important;
  margin: 5px 0px 0px 5px !important;
  padding-left: 3px !important;
  font-size: 13px !important;
  font-weight: 500;
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(255, 255, 255, 0.2);
}

.foot-lnk {
  text-align: center;
}

.forgot_pass {
  width: 100%;
  height: 60%;
  position: absolute;
  padding: 90px 70px 10px 70px;
  background: rgba(40, 57, 101, 0.9);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.forgot_wrap {
  /* width:60%; */
  margin: 0px auto;

  /* position:relative; */
}

.main-footer {
  position: absolute;
}

.nav-notification:hover {
  background-color: none !important;

}

.alert {
  display: none;
}

.loginfailed-text {
  color: rgb(133, 18, 18);
  cursor: pointer;
  top: 0px !important;
  text-align: left;
}

.login-text {
  color: green;
  text-align: left;
  margin-left: 20px;
  cursor: pointer;
  top: 0px !important;
}

/* .list
{
	list-style: none;
} */
.banner-settings {
  width: 50%;
  margin-left: 3px;
  margin-bottom: 20px;
  padding: 2px;
  height: auto;
  /* position:relative; */
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.logo-settings {
  width: 50%;
  margin-left: 5px;
  margin-bottom: 20px;
  padding: 2px;
  height: auto;
  /* position:relative; */
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.user-profile {
  width: 100%;
  margin-left: 440px;
  margin-bottom: 20px;
  max-width: 758px;
  min-height: 400px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.main-sidebar {
  /* top:60px !important; */
  bottom: 0 !important;
  width: 250px;
  border: none !important;
  position: relative !important;
  height: 100vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #293043;

}

.main-sidebar::-webkit-scrollbar {
  width: 10px;
}


.main-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.main-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
  .pushmenu {
    display: block;
  }

  /* show it on small screens */
  /* .navbar{width:100%;display:inline} */
}

@media screen and (min-width: 401px) and (max-width: 3000px) {
  .pushmenu {
    display: none;
  }

  /* hide it elsewhere */
}

.nav-link {
  margin-left: 30px;
}

.nav-item {
  /* border:1px solid white; */
  padding: 10px;
}

.header-item i {
  color: white !important;
  margin-left: 50px !important;

  margin-top: 0px !important;
}

/* .nav-dashboard
{
	color: white !important;

}
.nav-dashboard:hover
{
	color: white !important;
	background-color: #FCC10C !important;



} */

.list-item :hover {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.forgotpass {
  color: white;
  text-decoration: underline;
  margin-top: 3px;
}

.select_image {
  margin-left: 200px;
}

.save_banner {
  margin-left: 180px;
}

.hide_div {
  top: 0px;
  left: 0px;
  border-top: 10px;
  width: 100%;
  height: 40px;
}

.AddAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

.EditAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

.DeleteAlert {
  position: absolute;
  top: 10px;
  left: 250px;
  border-top: 10px;
  width: 40%;
  height: 40px;
}

/* toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: red;
}

input:focus+.slider {
  box-shadow: 0 0 1px red;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.nav-item p:hover,
.nav-item i:hover {
  color: black !important;
}

/* .menu_item:hover
  {
     color: white !important;
  } */
.active {
  color: black !important;
}

.deactive {
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 10px;
  width: 100%;
  height: 40px;
  display: none;
}

.back_button {
  width: "100px";
  height: "100px";
}

.form-control {
  /* width: 290px !important; */
  background: transparent;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid white !important;
  border-radius: 0px !important;
  color: white !important;
}

.form-control:focus {
  /* width: 290px !important; */
  background: transparent;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid white !important;
  color: white !important;
}

.college_login {
  color: white;
  background-color: #1f3977;
  padding: 3px;
  animation: zoom-in-zoom-out 2s ease infinite;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
  border: none !important;
  font-family: "Source Sans Pro", sans-serif;
}

.login_button {
  /* background-color: #1F3977  !important; */
  border: none !important;
  background: #1f3977 !important;
  cursor: pointer !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.8, 0.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

input:focus::-webkit-input-placeholder {
  font-size: 0.75em;
  position: relative;
  /* top: -15px;  */
  /* border-bottom: 1px solid grey; */
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  /* border: none; */
  color: black;
}

input:focus {
  outline: none !important;
  /* border: 2px solid  var(--blue); */
  /* border-color: #719ECE; */
}

.content-header {
  /* padding:0px 0px 0px 0px; */
  /* background-color: white; */
  width: 100%;
  margin-right: 10px;
  /* box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19); */
}

.content-wrapper {
  /* padding: 15px 10px 10px 10px ;
	background: #e4e9f4 !important;
	overflow-y: hidden;
	overflow-x: hidden;
	font-family: Poppins;
	position: relative;
	width: 70%;
	height: 100vh !important;
	margin: 0px;
	margin-left: 0px !important;
	margin-top: 50px; */
}

.content-wrapper::-webkit-scrollbar {
  width: 10px;
}

.content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.first_part:hover,
.central_part:hover,
.right_part:hover {
  transform: scale(1);
  /* zoom: 50%; */
  border: 1px solid rgb(194, 189, 189);
}

.call_icon,
.mail_icon,
.chat_icon,
.calender_icon,
.phone_icon {
  color: black !important;
}

.call_icon:hover {
  color: #00f3b9 !important;
  cursor: pointer;
}

.mail_icon:hover {
  color: red !important;
  cursor: pointer;
}

.chat_icon:hover {
  color: #339dd8 !important;
  cursor: pointer;
}

.calender_icon:hover {
  color: green !important;
  cursor: pointer;
}

.phone_icon:hover {
  color: #fcc10c !important;
  cursor: pointer;
}

.contact_button {
  width: 70px;
  font-size: 10px;
  background-color: #1f3977 !important;
  border: none;
}

.contact_button:hover {
  background-color: #339dd8 !important;
}

.contact_icons {
  display: none;
}

.expand_map:hover {
  text-decoration: underline;
  color: white;
  /* transform: scale(1.1); */
}

.notification_icon {
  color: white !important;
  background-color: green !important;
  padding: 5px;
  height: 30px;
  border-radius: 10px 0px 0px 10px;
  margin-left: 10px;
}

.notification_icon:hover {
  color: white !important;
  background-color: green !important;
  padding: 5px;
  height: 30px;
  border-radius: 10px 0px 0px 10px;
  margin-left: 10px;
  text-decoration: none;
}

.content_center {
  /* background-color: rgb(230, 225, 225); */

  margin: 6px;
}

.services {
  background-color: white;
  border-left: 1px solid #d4d5db;
  padding: 15px;
}

/* .search-container {
	float: right;

  } */

/* .search-container input[type=text] {
	padding-left: 3px;

	font-size: 14px;
	border: none;
	width: 300px;
  } */

/* .search-container button {
	float: right;
	padding: 4px 10px;

	margin-right: 16px;
	background: #ddd;

	border: none;
	cursor: pointer;
  } */

/* .search-container button:hover {
	background: #ccc;
  } */

/* @media screen and (max-width: 600px) { */
/* .search-container {
	  float: none;
	} */
/* .search-container input[type=text], .search-container button {
	  float: none;
	  display: block;
	  text-align: left;
	  width: 100%;
	  margin: 0;
	  padding: 14px;
	} */
/* .search-container input[type=text] {
	  border: 1px solid #ccc;
	} */
/* } */

.student_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 10px 20px;
  margin: 10px 10px 10px 23px;

  border: none;
  cursor: pointer;

  transition: all 0.3s linear;

  background: #e44c7a;
}

.create_btn {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 10px;
  margin: 10px 10px 0px 0px;

  border: none;
  cursor: pointer;

  transition: all 0.3s linear;

  background: #339dd8;
}

.student_btn:hover {
  background: #eb5d88;
}

.student_persona {
  border: 1px solid white;
  margin: 10px 20px;
  height: auto !important;
  border-radius: 5px;
  background-color: white;
  color: black;
}

.student_groups {
  background-color: rgb(202, 199, 199);
  margin: 10px 10px 8px 10px;
  height: auto !important;
  padding: 10px 10px 0px 10px;
}

/* .student_groups a
{
	color: black !important;
/* } */

.nav-link p:hover {
  color: white !important;
}

.student_profile {
  background-color: white;
  color: black;
  margin: 10px 20px;
}

.international_std {
  background-color: white;
  padding: 0px;
}

.total_count {
  /* border-left: 6px solid #339dd8; */
  background-color: rgb(230, 222, 222);
  color: grey;
  margin: 10px 15px 10px 15px;
  padding: 5px;
}

.faq {
  /* border-left: 6px solid #339dd8; */
  background-color: #effeff;
  color: black;
  border: 1px solid #eaecef;
  border-radius: 4px;
  margin: 10px 1px 5px 1px;
  padding: 5px 5px 0px 10px !important;
}

.left_chatbox {
  /* border: 1px solid white; */
  border-radius: 20px 0px 0px 20px;
  width: 40%;
  font-size: 15px;
  background-color: rgb(230, 222, 222);
  padding: 5px;
  margin: 10px 0px 10px 10px;
}

.right_chatbox {
  border-left: 1px solid rgb(219, 216, 216);
  background-color: rgb(230, 222, 222);
  padding: 5px 5px 5px 0px;
  width: 60%;
  height: auto;
  margin: 10px 10px 10px 0px;
  border-radius: 0px 20px 20px 0px;
}

.left_sidebar {
  background-color: white;
  border-radius: 3px;
  margin: 8px;
  /* box-shadow:0 10px 3px 2px rgba(0,0,0,.24); */
}

.left_sidebar p {
  color: black;
}

/* chat search */
.chat_search {
  /* float: center; */
  margin-left: 40px;
  margin-top: 10px;
}

.chat_search input[type="text"] {
  padding-left: 35px;
  /* margin-bottom: 3px; */
  font-size: 14px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  width: 300px;
  background: rgb(180, 179, 179);
}

.chat_search button {
  float: right;
  padding: 4px 10px;
  border-radius: 0px 5px 5px 0px;
  margin-right: 16px;
  background: rgb(180, 179, 179);

  border: none;
  cursor: pointer;
}

.chat_search button:hover {
  background: #ccc;
}

.chat_search :-ms-input-placeholder {
  color: #c1c1c1 !important;
}

.chat_search ::placeholder {
  color: #c1c1c1 !important;
}

.inbox_chat_header {
  /* background-color:rgb(230, 222, 222); */
  color: black;
  /* padding: 2px 2px 0px 2px; */
  padding-bottom: 0 !important;
  /* font-size: 15px; */
}

.inbox_chat_footer {
  background-color: rgb(230, 222, 222);
  color: black;
  /* top: 20px; */
  border-radius: 0px 0px 20px 0px;
  position: relative;
}

.inbox_chat_footer:-ms-input-placeholder {
  margin-left: 2px;
  font-size: 15px !important;
}

.inbox_chat_footer::placeholder {
  margin-left: 2px;
  font-size: 15px !important;
}

.inbox_chat {
  background-color: rgb(114, 112, 112);
  height: auto;
  color: black;
  overflow-y: auto;
  padding: 2px;
}

/* Read More */
/* *{
	margin:0;
	padding:0;
	border:0;
	font-size: 16px;
	line-height: 1.4;
	font-family: sans-serif;
  }
  .body{
	padding:20px;
	margin:0 auto;
	max-width:80%;
	background:#fff;
	display:block;
	height:100vh;
  } */

h2,
details {
  display: inline;
}

details {
  position: relative;
}

details summary {
  display: block;
  cursor: pointer;
  color: white;
  background-color: #339dd8;
  /* margin-left:800px !important; */

  font-size: 15px;
  padding: 4px;
  border-radius: 3px;
}

details summary:focus {
  outline: none;
}

details[open] {
  display: block;
  padding-bottom: 25px;
  padding-top: 10px;
  animation: open 0.2s linear;
}

details[open] summary {
  position: absolute;
  bottom: 0;
  left: 0;
}

details #open {
  padding-left: 5px;
  text-align: middle;
}

details #open:after {
  display: inline-block;
  position: relative;
  top: -3px;
  padding-left: 8px;
  content: "\BB";
  transform: rotate(90deg);
}

details[open] #open {
  display: none;
}

details #close {
  display: none;
}

details[open] #close {
  display: block;
}

::-webkit-details-marker {
  display: none;
}

@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Text tabs */
article.tabs {
  position: relative;
  display: block;
  width: 100%;
  height: 800px;
  margin: 2px;
}

article.tabs section {
  position: absolute;
  display: block;
  top: 1.8em;
  left: 0;
  right: 0;
  height: auto;
  padding: 10px 20px;
  /* border: 1px solid blue; */
  background-color: #ddd;
  border-radius: 0px;

  z-index: 0;
  color: black;
}

article.tabs section .tab-content {
  display: none;
}

article.tabs section:last-child {
  z-index: 1;
  color: #333;

  background-color: #fff;
}

article.tabs section:last-child .tab-content {
  display: block;
}

article.tabs section h2 {
  position: absolute;
  font-size: 15px;
  font-weight: normal;

  width: 135px;
  height: 1.8em;
  top: -1.8em;
  left: 0px;
  padding: 0;
  margin: 0;
  border: 1px solid rgb(202, 201, 201);
  border-bottom: none;
  color: rgb(129, 127, 127);
  background-color: #ddd;
  border-radius: 5px 5px 0 0;
}

/* persona */
article.tabs section:last-child h2 {
  color: #333;

  background-color: #fff;
}

article.tabs section:nth-child(1) h2 {
  left: 132px;
}

article.tabs section:nth-child(2) h2 {
  left: 254px;
}

article.tabs section h2 a {
  display: block;
  width: 100%;
  line-height: 1.8em;
  text-align: center;

  text-decoration: none;
  color: inherit;
  outline: 0 none;
}

article.tabs section:target,
article.tabs section:target h2 {
  color: #333;
  background-color: #fff;

  z-index: 2;
}

article.tabs section:target .tab-content {
  display: block;
}

article.tabs section:target~section:last-child h2 {
  color: #999;
  background-color: #ddd;
}

article.tabs section:target~section:last-child .tab-content {
  display: none;
}

/* Modal */
.modal-window {
  position: fixed;

  background: rgba(0, 0, 0, 0.5);
  width: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0.5;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 790px;
  transform: translate(-50%, -50%);
  padding: 15px;
  background: white;
  border: 1px solid #1f3977;
  border-top: none;
}

@media (min-width: 280px) and (max-width: 500px) {
  .modal-window>div {
    left: 124px !important;
    height: auto !important;
    width: 250px !important;
    top: 266px !important;
  }
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

/* #close {
    float:right;
    display:inline-block;
    padding:2px 5px;
    background:#ccc;
} */
.modal-close {
  color: white;
  background-color: #1f3977;
  line-height: 30px;
  font-size: 80%;
  position: absolute;
  right: 0;
  left: 0;
  text-align: right;
  padding-right: 8px;
  top: 0;
  width: 100%;
  text-decoration: none;
}

.popup a:hover {
  color: grey !important;
}

a:hover {
  text-decoration: none !important;
}

/* a {
	color: inherit;
  }
   */
/* .container {
	display: grid;

	width: 100% !important;
	margin: 0;

  }
   */
.modal-window>div {
  /* border-radius: 1rem; */
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 0px;
}

small {
  color: lightgray;
}

.btn {
  background-color: #339dd8;
  color: white;
  /* padding: 1em 1.5em; */
  border-radius: 5px;
  /* text-decoration: none; */
}

.btn i {
  /* padding-right: 0.3em; */
}

/* custome tabs */

/* .tab-container{
	margin: 10px 30px 10px 30px ;
	background-color: white;
	padding: 5px 0px;

} */

.job-tab-link:hover,
li.current {
  background: #c4c4c4 !important;
  color: #fff !important;
}


.job-tab-container {

  border-radius: none !important;
  background-color: white !important;
}


ul.job-tabs {
  list-style: none;
  /* font-size: 2.75vmin; */
  line-height: 1.6;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

ul.job-tabs li {
  width: 11% !important;
  color: #000;
  text-align: center;
  padding: 3px 0px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px !important;
  cursor: pointer;
}

ul.job-tabs li:first-child {
  border-radius: 5px !important;
}

ul.job-tabs li:last-child {
  border-radius: 5px !important;
}

ul.job-tabs,
.job-tab-link {
  transition: all 0.5s ease-in-out;
}

.job-tab-link:hover {
  color: #fff;
}
.job-tab-content {
  /* display: none; */
  /* padding: 1vmin 5vmin 5vmin 5vmin; */
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}

.job-tab-content.current {
  display: inherit;
  background: ivory;
  border-radius: 5px !important;
  animation: fade 0.3s ease-in-out both;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.job-tab-content>* {
  margin: 1rem 0;
}

.job-tab-content img {
  max-width: 100%;
  border-radius: .5rem;
}

main {
  width: 100% !important;
  margin: 0 !important;
  /* border: none !important; */
  border-radius: 5px;
  max-width: 100% !important;

  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);

  /* padding: 2vmin 3vmin; */
}

main::-webkit-scrollbar {
  width: 10px;
}

main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.recharts-layer text {
  font-size: 14px !important;
}

.job-tab-container {

  border-radius: none !important;
  background-color: white !important;
}

.tab-menu {}

.tab-menu ul {
  margin: 0;
  padding: 0;
}

.tab-menu ul li {
  list-style-type: none;
  display: inline-block;
}

.tab-menu ul li a {
  text-decoration: none;
  color: rgb(109, 108, 108);
  background-color: darkgrey;
  margin-right: 1px;
  padding: 7px 25px;
  /* border-right: 2px solid rgb(202, 199, 199); */
  /* border-radius: 4px; */
}

.tab-menu ul li a.active-a {
  background-color: white;
  color: #339dd8;
  /* border-top: 2px solid #339dd8 ; */
}

.tab-menu ul li a.active-a:hover {
  background-color: white;
  color: #339dd8;
  border-top: 2px solid #339dd8;
}

.tab {
  display: none;
}

.tab h2 {
  color: rgba(0, 0, 0, 0.7);
}

.tab p {
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.tab-active {
  display: block;
}

/* hexagonal image */

/*
*, *::before, *::after {
  box-sizing: border-box;
} */

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  /* justify-content: center; */
  /* align-items: center; */
  max-width: 1200px;
  margin: 0 auto !important;
  padding: 0 !important;
  transform: translateY(34.375px);
}

.honeycomb-cell {
  flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4761904762px 12.5px 25px;
  position: relative;
  padding: 0.5em;

  text-align: center;
  z-index: 1;
}

.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}

.honeycomb-cell__title>small {
  font-weight: 300;
  margin-top: 0.25em;
}

.honeycomb-cell__image {
  object-fit: cover;
  object-position: center;
  background-color: grey;
}

.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}

.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell__image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.honeycomb-cell::before {
  background: #fff;
  transform: scale(1.055);
}

.honeycomb-cell::after {
  /* background: #3ea0eb; */
  opacity: 0.5;
  transition: opacity 350ms;
}

.honeycomb-cell:hover .honeycomb-cell__title {
  opacity: 0;
}

.honeycomb-cell:hover::before {
  background: grey;
}

.honeycomb-cell:hover::after {
  opacity: 0;
}

.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 81.25px 25px;
  }
}

@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - 275px);
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - 275px);
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: 1100px) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - 400px);
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - 400px);
  }

  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}

/* Alphabet Navigation list */
/*
  Our design has a min-width of 310px to work
*/
/* *, *:before, *:after {
  box-sizing: border-box;
} */

.AlphabetNav {
  width: 98% !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  background-color: transparent !important;
  border-radius: 5px;
  color: white !important;
  margin-bottom: none !important;
  margin-left: 8px;
}

.AlphabetNav a {
  display: flex;
  justify-content: center;
  /* padding: 0.5em; */
  border-radius: 8px;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  color: darkgray;
}

.AlphabetNav a:hover {
  background: #ddd;
  color: #444;
}

.AlphabetNav .active {
  /* background: #f46353; */
  /* color: white; */

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  background-color: transparent !important;
  border-radius: 5px;
  color: white !important;
  margin-bottom: none !important;
  /* margin-left:8px; */

  position: relative;
}

/*
  Contact List
*/
.ContactList {
  padding: 10em 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Contact {
  display: flex;
  align-items: center;
  width: 90%;
}

.ContactList .Contact {
  margin-bottom: 1.5rem;
}

.Contact-avatar {
  margin: 0 1em 0 0;
  width: 30%;
  min-width: 60px;
  max-width: 100px;
  border-radius: 50%;
  background: #a7c031;
}

.Contact-name {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: #339dd8;
}

.department-name {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: darkgray;
}

@media (min-width: 400px) {
  .AlphabetNav {
    top: 2vh;
    right: 0;
    height: 90vh;
    width: 9em;
  }

  .AlphabetNav a {
    width: calc(100% * 1 / 3 - 0.3em);
  }

  .ContactList {
    padding-top: 2rem;
  }
}

@media (min-width: 400px) and (min-height: 600px) {
  .AlphabetNav {
    width: 6em;
  }

  .AlphabetNav a {
    width: calc(100% * 1 / 2 - 0.5em);
    margin: 0.25em;
  }
}

@media (min-width: 800px) {
  .AlphabetNav {
    top: 0;
    display: flex;
    align-items: flex-start;
    flex-flow: nowrap row;
    justify-content: space-around;
    width: 100%;
    height: auto;
    background: ghostwhite;
  }

  .AlphabetNav a {
    margin: 0.5em 0;
    padding: 0.5em 0;
  }

  .ContactList {
    padding-top: 2px;
    /* background-color: white; */
    margin: 3px 0px;
    /* text-align: center; */
  }

  .Contact {
    width: calc(100% * 1 / 3 - 2rem);
    /* border: 1px solid grey; */
    padding: 10px;
    /* background-color: grey; */
    border-left: 6px solid #339dd8;
    background-color: rgb(230, 222, 222);
    color: #339dd8 !important;
    margin: 10px 0px 10px 0px;
    padding: 5px;
  }

  .Contact:hover {
    width: calc(100% * 1 / 3 - 2rem);
    /* border: 1px solid grey; */
    padding: 10px;
    transform: scale(1.1);
  }
}

/* new staff modal */
/* h1 {
	text-align: center;
	font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
	color: #333;
	text-shadow: 0 1px 0 #fff;
	margin: 50px 0;
  } */

#wrapper {
  width: 100px;
  margin: 0 820px;
  /* background: #fff; */
  padding-top: 8px;
  margin-bottom: none;
  /* border: 10px solid #aaa; */
  border-radius: 15px;
  background-clip: padding-box;
  text-align: center;
  display: flex;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  /* transition: opacity 200ms; */
  visibility: hidden;
  opacity: 0;
}

.overlay.light {
  background: rgba(255, 255, 255, 0.5);
}

.overlay .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: auto;
  padding: 20px;
  background: #fff;
  /* border: 1px solid #666; */
  width: auto;
  border-radius: 10px;
  /* box-shadow: 0 0 50px rgba(0, 0, 0, 0.5); */
  /* position: relative; */
  /* border: 1px solid black; */
}

.light .popup {
  border-color: #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.popup h2 {
  margin-top: 0;
  color: #666;
  font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  right: 5px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;

  color: #666;
}

.popup .close:hover {
  /* opacity: 1; */
}

.popup .content {
  max-height: 400px;
  overflow: auto;
}

.popup p {
  margin: 0 0 1em;
}

.popup p:last-child {
  margin: 0;
}

/* Button animation */

.buttonContainer {
  /* margin-top:100px; */
  text-align: center;
  display: inline-block;
  font: normal normal 300 1.3em "Open Sans";
  text-decoration: none;
  color: white;
  background-color: #339dd8;
  background-color: var(--blue);
  border: none;
  margin: 5px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, #1f3977 50%);
  transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
    color 0.5s ease 0s, background-color 0.5s ease;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .buttonContainer {
    margin-top: 8px !important;
  }
}

/* h1{
	color:rgba(69, 69, 69, 1);
	line-height:2em;
} */

/* .buttonContainer:hover{
	color:rgba(255, 255, 255, 1) !important;
	background-color:var(--blue) !important;
	background-position: -100% 100%;
	border:1px solid white;


} */
.buttonContainer a:hover {
  text-decoration: none !important;
  color: white !important;
}

.services p {
  color: black;
}

/* student view */
/** Inspired by: http://graphicburger.com/flat-design-ui-components/ **/
/** Line-chart and donut-chart made by @kseso https://codepen.io/Kseso/pen/phiyL **/

/************************************ FONTS ************************************/

/* entypo */
[class*="entypo-"]:before {
  font-family: "entypo", sans-serif;
}

/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: "FontAwesome", sans-serif;
}

/* zocial */
[class*="zocial-"]:before {
  font-family: "zocial", sans-serif;
}

@font-face {
  font-family: "icomoon";
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot");
  src: url("https://jlalovi-cv.herokuapp.com/font/icomoon.eot?#iefix") format("embedded-opentype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.ttf") format("truetype"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.woff") format("woff"),
    url("https://jlalovi-cv.herokuapp.com/font/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloudy:before {
  content: "\E60F";
}

.icon-sun:before {
  content: "\E610";
}

.icon-cloudy2:before {
  content: "\E611";
}

/************************************* END FONTS *************************************/

/* * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

/* .input-container {
	position: relative;
} */
input[type="text"] {
  /* width: 260px; */
  /* height: 35px; */
  /* margin-left: 20px;
		margin-bottom: 20px;
		padding-left: 45px; */
  /* background: #f4f4f4; */
  /* color: black; */
  /* border: solid 1px #1f253d; */
  /* border-radius: 30px;		 */
}

input[type="text"]::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

input[type="text"]:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input[type="text"]::-moz-input-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

/* input[type=text]:focus {
	   		outline: none;
	   		border: 1px solid #11a8ab;
	   	} */
.input-icon {
  font-size: 22px;
  position: absolute;
  left: 31px;
  top: 10px;
}

.input-icon.password-icon {
  left: 35px;
}

.horizontal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.horizontal-list li {
  float: left;
}

.clear {
  clear: both;
}

.icon {
  font-size: 25px;
}

.titular {
  display: block;
  line-height: 60px;
  margin: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button {
  display: block;
  width: 175px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  transition: background 0.3s;
}

.button:hover {
  text-decoration: none;
}

/* .arrow-btn-container {
	position: relative;
} */
.arrow-btn {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  transition: background 0.3s;
}

.arrow-btn:hover {
  text-decoration: none;
}

.arrow-btn.left {
  border-top-left-radius: 5px;
}

.arrow-btn.right {
  border-top-right-radius: 5px;
  right: 0;
  top: 0;
}

.arrow-btn .icon {
  display: block;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  line-height: 17px;
  width: 21px;
  margin: 20px auto;
  text-align: center;
}

.arrow-btn.left .icon {
  padding-right: 2px;
}

.profile-picture {
  border-radius: 100%;
  overflow: hidden;
  box-sizing: content-box;
}

.big-profile-picture {
  margin: 0 auto;
  /* border: 5px solid #50597b; */
  width: 150px;
  height: 150px;
}

.small-profile-picture {
  border: 2px solid #50597b;
  width: 40px;
  height: 40px;
}

/** MAIN CONTAINER **/

/* .main-container {
	font-family: 'Ubuntu', sans-serif;
	width: 950px;
	height: 1725px;
	margin: 6em auto;
}	 */
/*********************************************** HEADER ***********************************************/

.profile-menu {
  /*
			height: 50px;
			padding-right: 20px;
			margin-top:"none"; */
}

.profile-menu p {
  font-size: 17px;
  display: inline-block;
  line-height: 50px;
  margin: 0;
  padding-right: 10px;
}

.profile-menu a {
  padding-left: 5px;
}

.profile-menu a:hover {
  text-decoration: none;
}

.small-profile-picture {
  display: inline-block;
  vertical-align: middle;
}

/** CONTAINERS **/
/* .container {
		float: left;
		width: 300px;
	} */
.block {
  margin-bottom: 25px;
  background: #394264;
  border-radius: 5px;
}

/******************************************** LEFT CONTAINER *****************************************/
.inbox {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.inbox:hover {
  width: 36px !important;

  line-height: 22px !important;
  background-color: #e64c65 !important;
  text-align: center !important;
  border-radius: 15px !important;
  position: relative !important;
  top: 4px !important;
  float: right !important;
  right: 1px;
}

/* .donut-chart-block {
				height: 434px;
			} */
/* .donut-chart-block .titular {
					padding: 10px 0;
				} */
/* .donut-chart {
					height: 270px;
				} */
/******************************************
				DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
/* .donut-chart {
					  position: relative;
						width: 200px;
					  height: 200px;
						margin: 0 auto 2rem;
						border-radius: 100%
					 } */
/* p.center-date {
					  background: #394264;
					  position: absolute;
					  text-align: center;
						font-size: 28px;
					  top:0;left:0;bottom:0;right:0;
					  width: 130px;
					  height: 130px;
					  margin: auto;
					  border-radius: 50%;
					  line-height: 35px;
					  padding: 15% 0 0;
					} */
/* .center-date span.scnd-font-color {
					 line-height: 0;
					} */
/* .recorte {
					    border-radius: 50%;
					    clip: rect(0px, 200px, 200px, 100px);
					    height: 100%;
					    position: absolute;
					    width: 100%;
					  } */
/* .quesito {
					    border-radius: 50%;
					    clip: rect(0px, 100px, 200px, 0px);
					    height: 100%;
					    position: absolute;
					    width: 100%;
					    font-family: monospace;
					    font-size: 1.5rem;
					  }
					#porcion1 {
					    -webkit-transform: rotate(0deg);
					    transform: rotate(0deg);
					  }

					#porcion1 .quesito {
					    background-color: #E64C65;
					    -webkit-transform: rotate(76deg);
					    transform: rotate(76deg);
					  }
					#porcion2 {
					    -webkit-transform: rotate(76deg);
					    transform: rotate(76deg);
					  }
					#porcion2 .quesito {
					    background-color: #11A8AB;
					    -webkit-transform: rotate(140deg);
					    transform: rotate(140deg);
					  }
					#porcion3 {
					    -webkit-transform: rotate(215deg);
					    transform: rotate(215deg);
					  }
					#porcion3 .quesito {
					    background-color: #4FC4F6;
					    -webkit-transform: rotate(113deg);
					    transform: rotate(113deg);
					  }
					#porcionFin {
					    -webkit-transform: rotate(-32deg);
					    transform:rotate(-32deg);
					  }
					#porcionFin .quesito {
					    background-color: #FCB150;
					    -webkit-transform: rotate(32deg);
					    transform: rotate(32deg);
					  } */
/******************************************
				END DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.os-percentages {
  padding-top: 36px;
}

.os-percentages li {
  width: 75px;
  border-left: 1px solid #394264;
  text-align: center;
  background: #50597b;
}

.os {
  margin: 0;
  padding: 10px 0 5px;
  font-size: 15px;
}

.os.ios {
  border-top: 4px solid #e64c65;
}

.os.mac {
  border-top: 4px solid #11a8ab;
}

.os.linux {
  border-top: 4px solid #fcb150;
}

.os.win {
  border-top: 4px solid #4fc4f6;
}

.os-percentage {
  margin: 0;
  padding: 0 0 15px 10px;
  font-size: 25px;
}

.line-chart-block {
  height: 400px;
}

.line-chart {
  height: 200px;
  background: #11a8ab;
}

/******************************************
				LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.grafico {
  padding: 2rem 1rem 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  font-size: 80%;
}

.grafico span {
  display: block;
  position: absolute;
  bottom: 3rem;
  left: 2rem;
  height: 0;
  border-top: 2px solid;
  transform-origin: left center;
}

.grafico span>span {
  left: 100%;
  bottom: 0;
}

[data-valor="25"] {
  width: 75px;
  transform: rotate(-45deg);
}

[data-valor="8"] {
  width: 24px;
  transform: rotate(65deg);
}

[data-valor="13"] {
  width: 39px;
  transform: rotate(-45deg);
}

[data-valor="5"] {
  width: 15px;
  transform: rotate(50deg);
}

[data-valor="23"] {
  width: 69px;
  transform: rotate(-70deg);
}

[data-valor="12"] {
  width: 36px;
  transform: rotate(75deg);
}

[data-valor="15"] {
  width: 45px;
  transform: rotate(-45deg);
}

[data-valor]:before {
  content: "";
  position: absolute;
  display: block;
  right: -4px;
  bottom: -3px;
  padding: 4px;
  background: #fff;
  border-radius: 50%;
}

[data-valor="23"]:after {
  content: "+" attr(data-valor) "%";
  position: absolute;
  right: -2.7rem;
  top: -1.7rem;
  padding: 0.3rem 0.5rem;
  background: #50597b;
  border-radius: 0.5rem;
  transform: rotate(45deg);
}

[class^="eje-"] {
  position: absolute;
  left: 0;
  bottom: 0rem;
  width: 100%;
  padding: 1rem 1rem 0 2rem;
  height: 80%;
}

.eje-x {
  height: 2.5rem;
}

.eje-y li {
  height: 25%;
  border-top: 1px solid #777;
}

[data-ejeY]:before {
  content: attr(data-ejeY);
  display: inline-block;
  width: 2rem;
  text-align: right;
  line-height: 0;
  position: relative;
  left: -2.5rem;
  top: -0.5rem;
}

.eje-x li {
  width: 33%;
  float: left;
  text-align: center;
}

/******************************************
				END LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
				******************************************/
.time-lenght {
  padding-top: 22px;
  padding-left: 38px;
}

.time-lenght-btn {
  display: block;
  width: 70px;
  line-height: 32px;
  background: #50597b;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-right: 5px;
  transition: background 0.3s;
}

.time-lenght-btn:hover {
  text-decoration: none;
  background: #e64c65;
}

.month-data {
  padding-top: 28px;
}

.month-data p {
  display: inline-block;
  margin: 0;
  padding: 0 25px 15px;
  font-size: 16px;
}

.month-data p:last-child {
  padding: 0 25px;
  float: right;
  font-size: 15px;
}

.increment {
  color: #e64c65;
}

.media {
  height: 216px;
}

#media-display {
  position: relative;
  height: 180px;
  background: #787878 url("https://www.fancinema.com.ar/wp-content/uploads/catwoman1.jpg") center top;
}

#media-display .play {
  position: absolute;
  top: 75px;
  right: 32px;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 2px 5px 2px 9px;
}

#media-display .play:hover {
  border: 2px solid #e64c65;
}

.media-control-bar {
  padding: 6px 0 0 15px;
}

.media-btn,
.time-passed {
  display: inline-block;
  margin: 0;
}

.media-btn {
  font-size: 19px;
}

.media-btn:hover,
.media-btn:hover span {
  text-decoration: none;
  color: #e64c65;
}

.play {
  margin-right: 100px;
}

.volume {
  margin-left: 30px;
}

.resize {
  margin-left: 12px;
}

.left-container .social {
  height: 110px;
}

.left-container .social li {
  width: 75px;
  height: 110px;
}

.left-container .social li .icon {
  text-align: center;
  font-size: 20px;
  margin: 0;
  line-height: 75px;
}

.left-container .social li .number {
  text-align: center;
  margin: 0;
  line-height: 34px;
}

.left-container .social .facebook {
  background: #3468af;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.left-container .social .facebook .number {
  background: #1a4e95;
  border-bottom-left-radius: 5px;
}

.left-container .social .twitter {
  background: #4fc4f6;
}

.left-container .social .twitter .icon {
  font-size: 18px;
}

.left-container .social .twitter .number {
  background: #35aadc;
}

.left-container .social .googleplus {
  background: #e64c65;
}

.left-container .social .googleplus .number {
  background: #cc324b;
}

.left-container .social .mailbox {
  background: #50597b;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-container .social .mailbox .number {
  background: #363f61;
  border-bottom-right-radius: 5px;
}

/************************************************** MIDDLE CONTAINER **********************************/
.middle-container {
  margin: 0 25px;
}

.profile {
  height: 410px;
}

.add-button .icon {
  float: right;
  line-height: 18px;
  width: 23px;
  border: 2px solid;
  border-radius: 100%;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.add-button .icon:hover {
  color: #e64c65;
  border-color: #e64c65;
}

.user-name {
  margin: 25px 0 16px;
  text-align: center;
}

.profile-description {
  width: 210px;
  margin: 0 auto;
  text-align: center;
}

.profile-options {
  padding-top: 23px;
}

.profile-options li {
  border-left: 1px solid #1f253d;
}

.profile-options p {
  margin: 0;
}

.profile-options a {
  display: block;
  width: 99px;
  line-height: 60px;
  text-align: center;
  transition: background 0.3s;
}

.profile-options a:hover {
  text-decoration: none;
  background: #50597b;
}

.profile-options a:hover.comments .icon {
  color: #fcb150;
}

.profile-options a:hover.views .icon {
  color: #11a8ab;
}

.profile-options a:hover.likes .icon {
  color: #e64c65;
}

.profile-options .icon {
  padding-right: 10px;
}

.profile-options .comments {
  border-top: 4px solid #fcb150;
}

.profile-options .views {
  border-top: 4px solid #11a8ab;
}

.profile-options .likes {
  border-top: 4px solid #e64c65;
}

.weather {
  height: 555px;
}

.weather .titular {
  background: #cc324b;
}

.weather .titular .icon {
  padding-right: 15px;
  font-size: 26px;
}

.weather .current-day {
  height: 135px;
  background: #e64c65;
}

.weather .current-day p {
  margin: 0;
  padding-left: 50px;
}

.current-day-date {
  font-size: 16px;
  padding-top: 16px;
}

.current-day-temperature {
  font-size: 70px;
}

.current-day-temperature .icon-cloudy {
  padding-left: 20px;
}

.weather .next-days {}

.weather .next-days p {
  margin: 0;
  display: inline-block;
  font-size: 16px;
}

.weather .next-days a {
  display: block;
  line-height: 58px;
  border-bottom: 1px solid #1f253d;
  transition: background 0.3s;
}

.weather .next-days a:hover {
  background: #50597b;
}

.weather .next-days a:hover .day {
  color: #e64c65;
}

.weather .next-days-date {
  padding-left: 20px;
}

.weather .next-days-temperature {
  float: right;
  padding-right: 20px;
}

.weather .next-days-temperature .icon {
  padding-left: 10px;
}

.tweets {
  height: 375px;
}

.tweets .titular {
  background: #35aadc;
}

.tweets .titular .icon {
  font-size: 18px;
  padding-right: 20px;
}

.tweet.first {
  height: 150px;
  border-bottom: 1px solid #1f253d;
}

.tweet p:first-child {
  margin: 0;
  padding: 30px 30px 0;
}

.tweet p:last-child {
  margin: 0;
  padding: 15px 30px 0;
}

.tweet-link {
  color: #4fc4f6;
}

.middle-container .social {
  height: 205px;
  background: #1f253d;
}

.middle-container .social li {
  margin-bottom: 12px;
}

.middle-container .social a {
  line-height: 60px;
}

.middle-container .social a:hover {
  text-decoration: none;
}

.middle-container .social .titular {
  border-radius: 5px;
}

.middle-container .social .facebook {
  background: #3468af;
  transition: background 0.3s;
}

.middle-container .social a:hover .facebook {
  background: #1a4e95;
}

.middle-container .social a:hover .icon.facebook {
  background: #3468af;
}

.middle-container .social .twitter {
  background: #4fc4f6;
  transition: background 0.3s;
}

.middle-container .social a:hover .twitter {
  background: #35aadc;
}

.middle-container .social a:hover .icon.twitter {
  background: #4fc4f6;
}

.middle-container .social .googleplus {
  background: #e64c65;
  transition: background 0.3s;
}

.middle-container .social a:hover .googleplus {
  background: #cc324b;
}

.middle-container .social a:hover .icon.googleplus {
  background: #e64c65;
}

.middle-container .social .icon {
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.middle-container .social .icon.facebook {
  background: #1a4e95;
}

.middle-container .social .icon.twitter {
  background: #35aadc;
}

.middle-container .social .icon.googleplus {
  background: #cc324b;
}

/* Profile */

.snip1515 {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  margin-top: 10px;
  width: 98%;
  margin-left: 10px;
  /* margin-right: 100px !important; */

  color: #000000;
  text-align: center;
  /* line-height: 1.4em; */
  font-size: 14px;
  box-shadow: none !important;
}

.snip1515 * {
  box-sizing: border-box;
}

.snip1515 .profile-image {
  /* display: inline-block; */
  width: 10%;
  z-index: 1;
  margin-top: 0px !important;
  position: relative;
  padding: 5px 0px 5px 0px;
  border: 2px solid #e8b563;
}

.snip1515 .profile-image img {
  width: 40%;

  vertical-align: left;
}

.snip1515 figcaption {
  width: 100%;
  background-color: #f2f2f2;
  color: #555;
  padding: 10px 20px 20px;
  /* margin-top: -200px; */
  display: inline-block;
}

.snip1515 h3,
.snip1515 h4,
.snip1515 p {
  /* margin: 0 0 5px; */
}

.snip1515 p {
  margin: 0 0 5px;
}

.snip1515 h3 {
  font-weight: 600;
  font-size: 1.3em;
  font-family: "Playfair Display", Arial, sans-serif;
}

.snip1515 h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip1515 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
  margin-left: 0px !important;
}

.snip1515 .icons {
  text-align: center;
  width: 100%;
}

.snip1515 i {
  padding: 10px 2px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #e8b563;
  opacity: 0.75;
}

.snip1515 i:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

/* card animation */
/* :root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: "Rubik", sans-serif;
  --font-serif: "Cardo", serif;
}

* {
  box-sizing: border-box;
} */

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 1024px;
  margin: 0 auto;
  font-family: var(--font-sans);
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  /* padding: 1rem; */
  height: auto;
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
}

/* @media (min-width: 600px) {
  .card {
    height: 350px;
  }
} */
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}

.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.009) 11.7%,
      rgba(0, 0, 0, 0.034) 22.1%,
      rgba(0, 0, 0, 0.072) 31.2%,
      rgba(0, 0, 0, 0.123) 39.4%,
      rgba(0, 0, 0, 0.182) 46.6%,
      rgba(0, 0, 0, 0.249) 53.1%,
      rgba(0, 0, 0, 0.32) 58.9%,
      rgba(0, 0, 0, 0.394) 64.3%,
      rgba(0, 0, 0, 0.468) 69.3%,
      rgba(0, 0, 0, 0.54) 74.1%,
      rgba(0, 0, 0, 0.607) 78.8%,
      rgba(0, 0, 0, 0.668) 83.6%,
      rgba(0, 0, 0, 0.721) 88.7%,
      rgba(0, 0, 0, 0.762) 94.1%,
      rgba(0, 0, 0, 0.79) 100%);
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}

.card:nth-child(1):before {
  background-image: url(https://images.unsplash.com/photo-1517021897933-0e0319cfbc28?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

.card:nth-child(2):before {
  background-image: url(https://images.unsplash.com/photo-1533903345306-15d1c30952de?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

/* .card:nth-child(3):before {
  background-image: url(https://images.unsplash.com/photo-1545243424-0ce743321e11?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
} */
.card:nth-child(4):before {
  background-image: url(https://images.unsplash.com/photo-1531306728370-e2ebd9d7bb99?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
}

/* .content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
} */
/* .content > * + * {
  margin-top: 1rem;
} */

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;
}

.btn:hover {
  background-color: #0d0d0d;
  color: white;
}

.btn:focus {
  outline: 1px dashed yellow;
  outline-offset: 3px;
}

@media (hover: hover) and (min-width: 600px) {
  .card:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));
  }

  .content>*:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }

  .card {
    align-items: center;
  }

  .card:hover:before,
  .card:focus-within:before {
    /* transform: translateY(-4%); */
  }

  .card:hover:after,
  .card:focus-within:after {
    /* transform: translateY(-50%); */
  }

  .card:hover .content,
  .card:focus-within .content {
    transform: translateY(0);
  }

  .card:hover .content>*:not(.title),
  .card:focus-within .content>*:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .card:focus-within:before,
  .card:focus-within:after,
  .card:focus-within .content,
  .card:focus-within .content>*:not(.title) {
    transition-duration: 0s;
  }
}

/* emoji picker*/

/* * {
  list-style: none;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
#emoji-picker {
  display: flex;
  flex-flow: column nowrap;
  margin: 1em auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  /* max-width: 320px; */
  display: none;
  width: 100% !important;
}

#emoji-picker input[type="search"] {
  font-size: 14px;
  height: 30px;
  width: 98%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0 12px;
  margin: 8px;
  outline: none;
}

#emoji-picker #container {
  max-height: 194px;
  overflow-y: scroll;
  padding: 0 8px;
}

#emoji-picker #container p {
  color: #90949c;
  font-size: 10px;
  text-transform: uppercase;
}

#emoji-picker #container ol {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 4px;
}

#emoji-picker #container ol li {
  cursor: default;
  width: 10%;
  position: relative;
  overflow: hidden;
}

#emoji-picker #container ol li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}

#emoji-picker #container ol li:hover {
  background: #e6e6e6;
  border-radius: 4px;
}

#emoji-picker #container ol li span {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -42%);
}

#emoji-picker #categories {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  color: black;
}

#emoji-picker #categories li {
  cursor: default;
  width: 10%;
  position: relative;
}

#emoji-picker #categories li:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 85%;
}

#emoji-picker #categories li.active {
  background: #cce2ff;
  border-radius: 4px;
}

#emoji-picker #categories li span {
  font-size: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 4px;
}

.emoji-wysiwyg-editor {
  border: none !important;

  border-radius: 30px !important;
  background-color: #f0f2f5;
  height: 25px !important;
  padding-left: 15px !important;
}

.emoji-wysiwyg-editor:-ms-input-placeholder {
  font-size: 10px !important;
}

.emoji-wysiwyg-editor::placeholder {
  font-size: 10px !important;
}

.emoji-picker-icon {
  right: 0px !important;
  top: 10px !important;
}

.emoji-menu {
  width: 100% !important;
  border: none !important;
  margin-top: 10px !important;
  z-index: 5 !important;
  position: relative !important;
  overflow-y: auto;
}

.emoji-menu .emoji-items-wrap {
  height: 80px !important;
  overflow-y: auto !important;
}

/* graph */

@keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    visibility: visible;
  }
}

@keyframes animate-height {
  0% {
    height: 0;
  }

  100% {
    visibility: visible;
  }
}

#bar-chart {
  height: auto;
  width: 70%;
  position: relative;
  margin: 50px auto 0;
}

#bar-chart * {
  box-sizing: border-box;
}

#bar-chart .graph {
  height: 283px;
  position: relative;
}

#bar-chart .bars {
  height: 253px;
  padding: 0 2%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

#bar-chart .bar-group {
  display: block;
  float: left;
  height: 100%;
  position: relative;
  width: 12%;
  margin-right: 10%;
}

#bar-chart .bar-group:last-child {
  margin-right: 0;
}

#bar-chart .bar-group .bar {
  visibility: hidden;
  height: 0;
  animation: animate-height;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #2d2d2d;
  border-radius: 3px 3px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 0;
  position: absolute;
  text-align: center;
  width: 25%;
}

#bar-chart .bar-group .bar:nth-child(2) {
  left: 35%;
}

#bar-chart .bar-group .bar:nth-child(3) {
  left: 70%;
}

#bar-chart .bar-group .bar span {
  display: none;
}

#bar-chart .bar-group .bar-1 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

#bar-chart .bar-group .bar-2 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

#bar-chart .bar-group .bar-3 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

#bar-chart .bar-group .bar-4 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

#bar-chart .bar-group .bar-5 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}

#bar-chart .bar-group .bar-6 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

#bar-chart .bar-group .bar-7 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}

#bar-chart .bar-group .bar-8 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

#bar-chart .bar-group .bar-9 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
}

#bar-chart .bar-group .bar-10 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

#bar-chart .bar-group .bar-11 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
}

#bar-chart .bar-group .bar-12 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
}

#bar-chart .bar-group .bar-13 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}

#bar-chart .bar-group .bar-14 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
}

#bar-chart .bar-group .bar-15 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
}

#bar-chart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#bar-chart .x-axis {
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

#bar-chart .x-axis li {
  float: left;
  margin-right: 10.5%;
  font-size: 11px;
  width: 11.5%;
}

#bar-chart .x-axis li:last-child {
  margin-right: 0;
}

#bar-chart .y-axis {
  position: absolute;
  text-align: right;
  width: 100%;
}

#bar-chart .y-axis li {
  border-top: 1px solid #4e5464;
  display: block;
  height: 63.25px;
  width: 100%;
}

#bar-chart .y-axis li span {
  display: block;
  font-size: 11px;
  margin: -10px 0 0 -60px;
  padding: 0 10px;
  width: 40px;
}

#bar-chart .stat-1 {
  background-image: linear-gradient(to right,
      #ff4500 0%,
      #ff4500 47%,
      #cf3a02 50%,
      #cf3a02 100%);
}

#bar-chart .stat-2 {
  background-image: linear-gradient(to right,
      #b8f123 0%,
      #b8f123 47%,
      #79a602 50%,
      #79a602 100%);
}

#bar-chart .stat-3 {
  background-image: linear-gradient(to right,
      #00c5ff 0%,
      #00c5ff 47%,
      #0383a9 50%,
      #0383a9 100%);
}

.list_element:hover {
  color: grey !important;
}

.central_tab_menu:hover {
  transform: scale(0.9);
}

/* donut chart */

/* line chart */
.pie-chart {
  background: radial-gradient(circle closest-side,
      #f2f2f2 0,
      #f2f2f2 46.02%,
      transparent 46.02%,
      transparent 59%,
      #f2f2f2 0),
    conic-gradient(from 120deg,
      #4e79a7 0,
      #4e79a7 38%,
      /* #f28e2c 0,
			#f28e2c 61%, */
      /* #e15759 0,
			#e15759 77%, */
      /* #76b7b2 0,
			#76b7b2 87%, */
      #59a14f 0,
      #59a14f 93%,
      #edc949 0,
      #edc949 100%);
  position: relative;
  width: 100%;
  min-height: 350px;
  background-color: #f2f2f2;
  margin: 0;
}

.pie-chart h2 {
  position: absolute;
  margin: 1rem;
}

.pie-chart cite {
  position: absolute;
  bottom: 0;
  font-size: 80%;
  padding: 1rem;
  color: gray;
}

.pie-chart figcaption {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: smaller;
  text-align: right;
}

.pie-chart span:after {
  display: inline-block;
  content: "";
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.4em;
  height: 0.8em;
  border-radius: 0.2em;
  background: currentColor;
}

/* Progress status */
.progress_status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  color: #293043;
  color: var(--darkBlue);
  font: normal 64px Optima;
  text-align: center;
}

.progress_status:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  font-size: 0;
}

.progress_status:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  border: 30px solid #fa0;
  border-radius: 50%;
  box-sizing: border-box;
}

.progress_status b:after,
.progress_status b:before {
  content: "";
  position: absolute;
  z-index: 2;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 30px solid;
  border-radius: 50%;
  border-color: transparent;
  border-top-color: #0af;
  box-sizing: border-box;
  transform: rotate(45deg);
}

.progress_status b::after {
  transform: rotate(99deg);
  z-index: 3;
}

/* activity log */
.dateclass {
  width: 30%;
  border: 1px solid darkgrey;
  margin-left: 0px !important;
}

.dateclass.placeholderclass::before {
  width: 100%;
  content: attr(placeholder);
  margin-left: 0px !important;
}

.dateclass.placeholderclass:hover::before {
  margin-left: 0px !important;
  width: 0%;
  content: "";
}

/* design activity log box */
/* .container {
	padding: 5% 5%;
  }
   */
/* CSS talk bubble */
.talk-bubble {
  margin: 40px;
  margin-top: 0px;
  display: inline-block;
  position: relative;
  width: 470px;
  height: auto;
  background-color: rgb(223, 219, 219);
  margin-left: 2px;
}

.border {
  border: 8px solid #666;
}

.round {
  /* border-radius: 30px; */
  /* -webkit-border-radius: 30px; */
  /* -moz-border-radius: 30px; */
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: rgb(223, 219, 219) transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}

.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent rgb(223, 219, 219);
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) transparent transparent rgb(223, 219, 219);
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) transparent transparent;
}

/*
	  left: -8px;
	right: auto;
	top: auto;
	  bottom: -40px;
	  border: 32px solid;
	  border-color: transparent transparent transparent #666;
	  left: 0px;
	right: auto;
	top: auto;
	  bottom: -20px;
	  border: 22px solid;
	  border-color: transparent transparent transparent lightyellow;

  /*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: rgb(223, 219, 219) rgb(223, 219, 219) low transparent transparent;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: rgb(223, 219, 219) transparent transparent rgb(223, 219, 219);
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: rgb(223, 219, 219) transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

/* activity log */
/*- Screen Sizes*/
/*import fonts*/
/*media queries*/
/* html {
  box-sizing: border-box;
} */

/* *, *:before, *:after {
  box-sizing: inherit;
} */

.wrapper {
  width: 100% !important;
}

.timeline {
  line-height: 1.5em;
  font-size: 14px;
  transition: all 0.4s ease;
  position: relative;
  counter-reset: section;
}

.timeline:before {
  content: "";
  width: 5px;
  height: 100%;
  background: darkgrey;
  position: absolute;
  top: 0;
  left: -3.313em;
  border-radius: 40px 40px;
}

.timeline--entry {
  position: relative;
  background-color: #eeeeee;

  margin-bottom: 0px !important;
}

.timeline--entry__title {
  background-color: #dddddd;
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 300;
  color: #339dd8;
  text-align: left;
  padding: 6px 0px 0px 10px;
  font-size: 15px;

  /* padding: 1em; */
}

.timeline--entry__title:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -1em;
  top: 1em;
  border-right: 10px solid #dddddd;
}

.timeline--entry__detail {
  background-color: #dddddd;
  /* padding: 1em; */
  padding: 0px 0px 5px 10px;
  text-align: left;
  font-size: 14px;
}

.timeline--entry:before {
  content: "";
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;

  background-color: #339dd8;

  background-color: var(--blue);
  border: 0.2em solid white;
  border-radius: 50%;
  position: absolute;
  counter-increment: section;
  content: counter(section);
  text-shadow: 0 1px 0 #424242;
  left: -4.5em;
  margin-bottom: 0px;
}

@supports (display: grid) {
  @media (min-width: 768px) {
    .timeline {
      display: grid;
      grid-gap: 4em;
      grid-template-areas: ".  entry1" "entry2  ." ". entry3" "entry4 ." ". entry5";
    }

    .timeline:before {
      left: 49.5%;
    }

    .timeline--entry:nth-child(1) {
      grid-area: entry1;
    }

    .timeline--entry:nth-child(2) {
      grid-area: entry2;
    }

    .timeline--entry:nth-child(3) {
      grid-area: entry3;
    }

    .timeline--entry:nth-child(4) {
      grid-area: entry4;
    }

    .timeline--entry:nth-child(5) {
      grid-area: entry5;
    }

    .timeline--entry:nth-of-type(odd):before {
      left: -3.5em;
    }

    .timeline--entry:nth-of-type(even):not(:nth-of-type(odd)) .timeline--entry__title:before {
      left: 100%;
      border-left: 10px solid #dddddd;
      border-right: 0;
    }

    .timeline--entry:nth-of-type(even):before {
      left: 102%;
    }
  }
}

/* datatable */

/* .dataTables_filter input{

	border: none !important;
border-radius: 30px !important;
background-color: white !important;
	color: darkgrey !important;
	margin-left:10px !important;
 }
 .dataTables_filter input:focus
 {
	color: black !important;
	border: 1px solid black !important;
	box-shadow: none;
 }
 .dataTables_filter {
	width: 100% !important;

	margin-left: 330px !important;
 } */

.dataTables_length {
  width: 45% !important;

  float: left !important;

}

.dataTables_length select {


  color: black !important;
  margin-left: 0px !important;
  border: 1px solid black !important;
}

.dataTables_length select:focus {
  border: 1px solid black !important;
  border: 1px solid grey;
  color: black !important;
  margin-left: 0px !important;
  box-shadow: none;
}

table.dataTable.tablesorter thead th,
table.dataTable.tablesorter tfoot th {
  background-color: #d6e9f8;
  text-align: left;
  border: 1px solid #ccc;
  font-size: 11px;
  padding: 4px;
  color: #333;
}

:-ms-input-placeholder {
  margin-left: 0px !important;
}

::placeholder {
  margin-left: 0px !important;
}

/* style table */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* student table */
.student_table {
  width: 100%;
  padding: 5px;
  border: none;
}

.student_table th {
  color: #1f3977;
  float: left;
  margin-left: 10px;
  border: none;
}

.student_table td {
  color: #1f3977;
  float: left;
  border: none;
}

.table {
  border-collapse: separate !important;
  border-spacing: 0 2px !important;
  margin-top: 20px;
}

.table tr {
  background: white;
  border: none !important;
}

.table td {
  text-align: center;
}

/* modalpop up animation */
.popup-flex button {
  border: none;
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  font-family: "Saira Semi Condensed", sans-serif;
}

.popup-flex {
  margin: 30px;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
}

.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}

.popup-content span {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.show {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.more-articles {
  margin: 30px 30px 50px;
  font-size: 20px;
}

.more-articles a {
  margin: 0 10px;
  text-decoration: underline;
}

.onlineStatus {
  background: #21bd5a;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: absolute;
  margin-left: 38px;
  margin-top: 35px;
}

.ring-container {
  position: absolute;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 35px;
  left: 858px;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 853px;
  top: 30px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

/* dropdown on hover */
.dropbtn {
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background: white;
  width: 40px;
  height: 40px;
  /* padding:8px; */
}

/* dropdown notification */
.dropbtn-notification {

  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: white;
  padding: 0px;
}

.dropdown-content-notification {
  display: none;
  position: absolute;
  right: 0;
  margin-top: 10px;
  border: 1px solid none;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 280px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-notification a {
  color: black;
  height: auto;
  width: auto;
  text-decoration: none;
  display: block;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  margin-top: 10px;
  border: 1px solid #dbdbdd;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 300px;
  /* max-width: 400px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  height: auto;
  width: auto;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  border-radius: 10px;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

.dropdown:hover .dropbtn {
  background-color: white;
}

.notification_dropup {
  padding-top: 10px;
}

.notification_dropup:hover {
  background: #e5f1ff;
}

/* vertical dropdown menu */

/* * {
	margin: 0;
	padding: 0;

	box-sizing: border-box;
  } */

.profile_dropdown {
  /* position: ; */
  /* top: 50%;
	left: 50%;
	width: 300px; */
  background: transparent;
  color: black !important;
  font-size: 16px;
  /* transform: translateX(-50%) translateY(-50%); */
  /* box-shadow: 0 0 10px rgba(0,0,0,0.9); */
}

.profile_dropdown:hover {
  color: white !important;
}

.profile_dropdown input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  cursor: pointer;
}

.profile_dropdown ul {
  margin: 0;
  padding: 0;
}

.profile_dropdown a {
  display: block;
  padding: 15px 20px;
  color: black;
  text-decoration: none;
}

.profile_dropdown a:hover {
  display: block;
  padding: 15px 20px;
  color: black !important;
  text-decoration: none;
}

.profile_dropdown li {
  position: relative;
  float: left;
  width: 100%;
  list-style: none;
  color: black;
  transition: 0.5s;
  /* border-top: 1px solid #555; */
}

li {
  list-style-type: none;
}

.profile_dropdown li:first-child {
  border-top: none;
}

.profile_dropdown ul>li.sub>a:after {
  pointer-events: none;
  position: relative;
  float: right;
  font-family: "Font Awesome 5 Free";
  content: "\F054";
  font-weight: 900;
  margin-top: -0.1em;
  font-size: 1.2em;
  vertical-align: middle;
  transition: 0.5s;
}

.profile_dropdown li:hover {
  /* background: var(--darkBlue) !important; */
  color: black !important;
  border: none;
}

.profile_dropdown .submenu {
  max-height: 0;
  overflow: hidden;
  border-top: none;
  transition: max-height 0.5s ease-in-out;
}

.profile_dropdown input[type="checkbox"]:checked~.submenu {
  max-height: 999px;
  border-top: 1px solid #555;
}

.profile_dropdown input[type="checkbox"]:checked~a:after {
  transform: rotate(90deg);
}

.profile_dropdown .submenu a {
  padding: 10px 20px;
}

.profile_dropdown .submenu li {
  list-style-position: inside;
  padding: 10px 20px;
  list-style-type: square;
  /* background: #333; */
}

.profile_dropdown .submenu li.sub {
  list-style: none;
}

.profile_dropdown .submenu li li {
  background: transparent;
}

.profile_dropdown .submenu li li li {
  background: #2a2a2a;
}

/* dashboard */
.small-box {
  /* border: 1px solid darkgrey; */
  background: #ffffff;
  margin-bottom: 0 !important;
  border-radius: 10px;
  /* padding: 10px; */
  color: black;

  width: 100% !important;
  /* border-radius: 10px!important; */
}

.small-box::-webkit-scrollbar {
  width: 5px;
}

.small-box::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 8px;
}

.small-box::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  */
  background-color: rgb(196, 193, 193);
}

@media only screen and (max-width: 600px) {
  .small-box {
    width: 100%;
  }
}

.nextMonthCalender {
  width: 100%;
  border: none;

  padding: 5px 25px !important;
  border: none !important;
  background-color: transparent !important;
}

/* react calender */
.react-calendar {

  border: none;
  /* font-family: Arial, Helvetica, sans-serif; */
  flex-wrap: wrap;
  padding: 5px;
  width: auto;
}

.react-calendar--doubleView {
  width: 700px;
  border: 1px solid red;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0 !important;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  /* padding: 0 !important;
  margin: 0px !important; */
  /* font-size: 2rem !important; */
  /* border:1px solid blue !important; */
  /* color: rgb(15, 70, 15); */
  /* width:10px !important; */
  font-weight: 500 !important;
  font-size: 12px !important;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  /* border-radius: 50%;
  width: 25px;
  height: 25px; */
}

/* .react-calendar__navigation {
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.react-calendar__navigation button {
  width: 0px;
  height: 0px;
  background: none;
  display: none;

  padding: 0;
  margin: 0;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;

  display: none;
} */
.react-calendar__month-view__weekdays {
  /* text-align: center; */
  text-transform: none !important;
  /* font-weight: bold; */
  height: 30px !important;
  width: 100% !important;
  font-size: 15px !important;
  /* padding: 0px !important;
  margin: 0 !important; */
}

.react-calendar__month-view__weekdays {
  height: 20px !important;
}

.react-calendar__month-view__weekdays__weekday {

  height: 20px !important;
  width: 10px !important;
  font-size: 10px !important;
  text-align: center !important;
  font-family: Poppins;
  /* border: 0.5px solid #eceaea !important; */
  /* background-color: #d10000 !important; */
  /* color: white !important; */
  font-weight: 500 !important;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
  border: 1px solid red !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  /* display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px !important; */
  /* padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: white !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  /* padding: 2em 0.5em; */
}

.react-calendar__tile {
  width: 10px !important;
  height: 40px !important;
  text-align: center;
  font-size: 14px !important;

  background-color: white !important;
  /* border: 1px solid #eceaea !important; */
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: red !important; */
}

/* .react-calendar__navigation button{
  font-size: 24px !important;
} */

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: none !important;
  color: black !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* .react-calendar__navigation__arrow {
  display: none;
  color: red;
  padding: 0;
  margin: 0;
} */

.react-calendar__navigation__next2-button {
  font-size: 24px !important;
}

.react-calendar__navigation__next-button {
  font-size: 24px !important;
}

.react-calendar__navigation__prev-button {
  font-size: 24px !important;
}

.react-calendar__navigation__prev2-button {
  font-size: 24px !important;
}


.highlight abbr {
  /* color: white !important; */
  border: none !important;
  /* background: #1f3977 !important; */
  background: #2D5DD033;
  border-radius: 50%;
  padding: 6px 7px !important;
  text-align: center !important;
  width: 10px !important;
  height: 20px !important;
  font-weight: 600;
}

.current_date abbr {

  border: none !important;
  /* background: #1f3977 !important; */
  background: #15A31233;
  border-radius: 50%;
  padding: 6px 7px !important;
  text-align: center !important;
  width: 10px !important;
  height: 20px !important;
  font-weight: 600;
}

/* .calendar header .month {
    padding: 0;
    margin: 0;
    display: none;
}

 .calendar header .btn-prev  {
   display: none;
}
.calendar header .btn-next {
	display: none;
 }  */
/* modal pop up animation */
.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog {
  transform: translate(0);
}

.faq_categories {
  text-align: left !important;
  font-size: 13px !important;
  border: 1px solid #eaecef !important;
  padding: 5px 10px !important;
  border-radius: 3px !important;
  margin: 0px 20px 5px 20px !important;
}

.react-tabs__tab--selected {
  color: #339dd8 !important;
  color: var(--blue) !important;
  background: white !important;
  border-top: 1px solid #339dd8 !important;
  border-top: 1px solid var(--blue) !important;
  border-left: 1px solid #339dd8 !important;
  border-left: 1px solid var(--blue) !important;
  border-right: 1px solid #339dd8 !important;
  border-right: 1px solid var(--blue) !important;
}

.faq_tabs .react-tabs__tab--selected {
  color: white !important;
  background: #1f3977 !important;
  border: none !important;
}

.react-tabs {
  display: flex;

  width: 470px;
  height: 400px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;

  /* color: white; */
  /* background: #3c3e43; */
}

.react-tabs__tab {
  /* height: 70px; */
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.tab-panel {
  display: none;
  margin-top: 0;
  width: 500px !important;
  border: 1px solid red;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 10px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}

/* animation to dropdown */
/* .dropdown-content {
	animation-duration: 3s;
	animation-name: slidein;
  }

  @keyframes slidein {
	0% {
		top: 0;
	  }



	  100% {
		top:40px;
	  }
  } */
.addcategory:hover {
  color: white;
}

.addnewscategory {
  color: #293043;
  color: var(--darkBlue);
}

/* hexagonal image */

.main-wrapper {
  /* width: 90%;
	max-width: 900px; */
  /* margin: 3em auto; */
  margin: 0px;
  text-align: center;
}

.badge {
  position: relative;
  margin: 5px 30px 20px 20px;
  width: 4em;
  height: 6.2em;
  border-radius: 10px;
  display: inline-block;
  top: 0;
  transition: all 0.2s ease;
}

.badge:before,
.badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge:before {
  transform: rotate(60deg);
}

.badge:after {
  transform: rotate(-60deg);
}

.badge:hover {
  top: -4px;
}

.badge .circle {
  width: 60px;
  height: 60px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge .circle i.fa {
  font-size: 2em;
  margin-top: 8px;
}

.badge .font {
  display: inline-block;
  margin-top: 1em;
}

.badge .ribbon {
  position: absolute;
  border-radius: 4px;
  padding: 5px 5px 4px;
  width: 100px;
  z-index: 11;
  color: #fff;
  bottom: 12px;
  left: 50%;
  margin-left: -55px;
  height: 15px;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background: linear-gradient(to bottom right, #555 0%, #333 100%);
  cursor: default;
}

.yellow {
  background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
  color: #ffb300;
}

.orange {
  background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
  color: #f68401;
}

.pink {
  background: linear-gradient(to bottom right, #f48fb1 0%, #d81b60 100%);
  color: #dc306f;
}

.red {
  background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
  color: #c62828;
}

.purple {
  background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
  color: #7127a8;
}

.teal {
  background: linear-gradient(to bottom right, #4db6ac 0%, #00796b 100%);
  color: #34a297;
}

.blue {
  background: linear-gradient(to bottom right, #4fc3f7 0%, #2196f3 100%);
  color: #259af3;
}

.blue-dark {
  background: linear-gradient(to bottom right, #1976d2 0%, #283593 100%);
  color: #1c68c5;
}

.green {
  background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
  color: #7cb342;
}

.green-dark {
  background: linear-gradient(to bottom right, #4caf50 0%, #1b5e20 100%);
  color: #00944a;
}

.silver {
  background: linear-gradient(to bottom right, #e0e0e0 0%, #bdbdbd 100%);
  color: #9e9e9e;
}

.gold {
  background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
  color: #b7892b;
}

footer {
  text-align: center;
  margin: 5em auto;
}

footer a {
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #111;
  border: 0.5px dashed #f8f8f8;
  color: #999;
  margin: 5px;
}

footer a:hover {
  background: #222;
}

footer a .icons {
  margin-top: 12px;
  display: inline-block;
  font-size: 20px;
}

/* tab menu */
.ah-tab-wrapper {
  position: relative;
}

.ah-tab-wrapper *,
.ah-tab-wrapper *:before,
.ah-tab-wrapper *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ah-tab {
  border-bottom: 1px solid #ebebeb;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: -0.3em;
}

.ah-tab::before,
.ah-tab::after {
  bottom: 0;
  content-jump-tab: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
}

.ah-tab::before {
  left: -100%;
  width: 30px;
  background: linear-gradient(to left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 1) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

.ah-tab::after {
  right: 100%;
  width: 80px;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.ah-tab-overflow-left::before {
  opacity: 1;
  left: 0;
}

.ah-tab-overflow-right::after {
  opacity: 1;
  right: 0;
}

.ah-tab-overflow-wrapper {
  position: absolute;
  width: 43px;
  height: 100%;
  right: 100%;
  opacity: 0;
  top: 0;
  z-index: 1;
  text-align: center;
}

.ah-tab-overflow-wrapper[data-ah-tab-active="true"] {
  right: 0;
  opacity: 1;
  transition: opacity 0.3s;
}

.ah-tab-overflow-menu {
  /* background: transparent url(images/ah-tab-overflow-menu.svg) center center no-repeat; */
  background-size: 18px;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.7;
}

.ah-tab-overflow-wrapper:hover .ah-tab-overflow-menu {
  opacity: 1;
}

.ah-tab-overflow-list {
  position: absolute;
  top: -1000%;
  right: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.09);
  list-style: none;
  transition: opacity 0.3s;
  opacity: 0;
  max-width: 290px;
}

.ah-tab-overflow-list::after {
  content-jump-tab: " ";
  display: block;
  position: absolute;
  top: -14px;
  right: 14px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: #fff;
}

.ah-tab-overflow-list::before {
  content-jump-tab: " ";
  display: block;
  position: absolute;
  top: -16px;
  right: 13px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #cdcdcd;
}

.ah-tab-overflow-wrapper:hover .ah-tab-overflow-list {
  top: 99%;
  padding: 10px 0;
  opacity: 1;
}

.ah-tab-overflow-list:hover {
  display: block;
}

.ah-tab-item {
  color: grey;
  /* background: red; */
  cursor: pointer;
  display: inline-block;
  letter-spacing: normal;
  transition: all 0.3s;
  text-decoration: none;
  /* padding:10px; */
  font-size: 16px;
}

.ah-tab {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
}

.ah-tab::-webkit-scrollbar {
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
  height: 5px;
}

.ah-tab::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 10px;
}

.ah-tab::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: darkgrey;
  margin-top: 10px;
}

.ah-tab>.ah-tab-item {
  border-bottom: 3px solid transparent;
  margin-right: 30px;
  padding: 15px 0;
}

@media (min-width: 768px) {
  .ah-tab>.ah-tab-item {
    margin-right: 50px;
  }
}

.ah-tab-item[data-ah-tab-active="true"] {
  /* color:white; */
  /* text-decoration:none; */
  /* color:#fff; */
  /* font-family:arial; */
  /* background-color:#1F3977 !important; */
  padding-bottom: 50px;
  border-radius: 0px 0px 100px 100px;
  border-bottom: 10px solid #c7c4c4;
  transition: 0.4s;
}

.ah-tab>.ah-tab-item:hover {
  border-bottom: 3px solid #c7c4c4;
  transition: 0.4s;
}

.ah-tab>.ah-tab-item[data-ah-tab-active="true"] {
  border-bottom-color: #c7c4c4;
  transition: 0.4s;
}

.ah-tab-overflow-list>.ah-tab-item {
  border-left: 3px solid transparent;
  display: block;
  overflow: hidden;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ah-tab-overflow-list>.ah-tab-item:hover {
  border-left-color: #8b8b8b;
  transition: 0.4s;
}

.ah-tab-overflow-list>.ah-tab-item[data-ah-tab-active="true"] {
  border-left-color: #215393;
}

.ah-tab-content-jump-tab-wrapper {
  margin: 30px 0;
}

.ah-tab-content-jump-tab {
  display: none;
  padding: 30px;
  border: 1px solid #ebebeb;
}

.ah-tab-content-jump-tab[data-ah-tab-active="true"] {
  display: block;
}

/* table */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  width: 100%;
  font-size: 0.9em;
  font-family: sans-serif;
  /* min-width: 400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #293043;
  background-color: var(--darkBlue);
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #339dd8;
  border-bottom: 2px solid var(--blue);
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

/* input emoji */
.full-width {
  width: 100%;
  height: 100vh;
  display: flex;
}

.full-width .justify-content-center {
  display: flex;
  align-self: center;
  width: 100%;
}

.full-width .lead.emoji-picker-container {
  width: 100%;
  display: block;
}

.full-width .lead.emoji-picker-container input {
  width: 100%;
  height: 50px;
}

/* faq categories */

.proposal {
  width: 100%;
}

/* ui
.ui-tabs-vertical
{
	width: 55em;
}
  .ui-tabs-vertical .ui-tabs-nav
  {
	  padding: .2em .1em .2em .2em;
	  float: left;
	  width: 12em;
	}
  .ui-tabs-vertical .ui-tabs-nav li
   {
	   clear: left;
	   width: 100%;
	   border: 1px solid black;
	   background: #f4f4f4;
	   border-bottom-width: 1px !important;
	   border-right-width: 1px !important;
	   margin: 0 -1px .2em 0;
	}
  .ui-tabs-vertical .ui-tabs-nav li a
  {
	  display:block;
	}
  .ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active
   {
	   padding-bottom: 5px;

	   padding-right: .1em;
	  background: var(--blue);
	color: white;
	   border-right-width: 1px;
	   border-right-width: 1px;
	}
  .ui-tabs-vertical .ui-tabs-panel
  {
	  padding: 1em;
	   float: right;
	   color: white;
	   width: 40em;
	} */

.unicircle_login {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f4f4f4;
  display: grid;
  width: 100%;
  padding: 0px !important;
  margin: 0;
}

.unicircle_login .container {
  position: absolute;

  max-width: 100%;
  display: grid;
  height: 100vh;

  background: #f4f4f4;
  overflow: hidden;
  margin: 0px !important;
  padding: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .unicircle_login .container {
    max-width: 100%;

    height: 100vh;
  }

  .unicircle_login .container .user .imgBx {
    width: 100%;
    height: 100%;
  }
}

.right_side_img {
  width: 100%;

  margin-top: 50px;
  padding: 0px 50px 50px 50px;
}

.unicircle_login .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.unicircle_login .container .user .imgBx {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  margin-right: 0 !important;
  transition: 0.5s;
}

.unicircle_login .container .user .imgBx img {
  position: absolute;
  top: 10px;
  left: 20px;
}

.unicircle_login .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #293043;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.unicircle_login .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

.unicircle_login .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

.unicircle_login .container .user .formBx form input[type="submit"] {
  max-width: 100px;
  background: #677eff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}

.unicircle_login .container .user .formBx form .signup {
  position: relative;
  margin-top: 0px;
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
}

.unicircle_login .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

.unicircle_login .container .signupBx {
  pointer-events: none;
}

.unicircle_login .container.active .signupBx {
  pointer-events: initial;
}

.unicircle_login .container .signupBx .formBx {
  left: 100%;
}

.unicircle_login .container.active .signupBx .formBx {
  left: 0;
}

.unicircle_login .container .signupBx .imgBx {
  left: -100%;
}

.unicircle_login .container.active .signupBx .imgBx {
  left: 0%;
}

.unicircle_login .container .signinBx .formBx {
  left: 0%;
}

.unicircle_login .container.active .signinBx .formBx {
  left: 100%;
}

.unicircle_login .container .signinBx .imgBx {
  left: 0%;
}

.unicircle_login .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  .unicircle_login .container {
    max-width: 400px;
  }

  .unicircle_login .container .imgBx {
    display: none;
  }

  .unicircle_login .container .user .formBx {
    width: 100%;
  }
}


@media (min-width: 1200px) {
  .container {
    width: 100% !important;
  }
}

.admin_dashboard h1 {
  /* margin-left: 80px; */
  color: white;
  font-size: 50px !important;
  font-weight: bold;
  text-shadow: 3px 0px white;
  line-height: 45px;
  margin-bottom: 50px;
  margin-top: 50px;
}

/* login pop up message */
.loginfailed-text {
  color: red;
  cursor: pointer;
  /* top: 0px !important; */
  /* text-align: center !important; */
  margin-left: 50px;
  margin-top: 5px;
}

.login-text {
  /* color: green; */
  /* text-align: center; */
  /* margin-left: 50px; */

  cursor: pointer;
  /* top: 0px !important; */
}

.myAlert-bottom {
  /* position: absolute;
		top: 290px !important; */
  border-radius: 5px;
  /* left:50px; */
  width: 100%;
  padding-top: 1px;
  border-left: 5px solid green;
  height: auto;
  background-color: #f4f4f4;
  color: red;
  display: none;
  min-height: 30px;
}

.failed-bottom {
  /* position: absolute; */
  /* top: 290px !important; */
  border-radius: 5px;
  /* left:50px; */
  width: 100%;
  border-left: 5px solid red;
  height: auto;
  background-color: #f4f4f4;
  color: red;
  display: none;
}

.logout_message {
  border: 1px solid red;
  text-align: center;
  z-index: 10;
  width: 100%;
  display: none;
}

/* @keyframes example {
		0%   {left:20px; top:0px;}
		25%  {left:150px; top:0px;}

		100% {left:20px; top:0px;}
	  }
	  @keyframes sucess_example {
		0%   {left:20px; top:0px;}
		25%  {left:150px; top:0px;}

		100% {left:20px; top:0px;}
	  } */

.unicircle_img {
  background-image: url(/static/media/image.419cc5a9.png);
  /* position:absolute; */
  background-repeat: no-repeat;
  background-size: cover;
  /* margin:10px ; */
  margin-top: 0px;
  width: 100px;
  /* border: 1px solid white; */
  height: 120px;
}

li {
  list-style-type: none;
}

.success_img {
  background-image: url(/static/media/success.8288c054.gif);
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  width: 35px;
  /* border: 1px solid white; */
  height: 30px;
  margin-left: 8px;
}

.fail_img {
  background-image: url(/static/media/wrong.9fcc0b69.gif);
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  width: 30px;
  /* border: 1px solid white; */
  height: 30px;
  margin-left: 8px;
}

.list-inline-item {
  font-size: 12px;
  text-align: center;
  color: black;
}

label {
  cursor: pointer;
  /* color: black !important; */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/* student tabs*/

.tabs {
  width: 100%;
  height: auto;
  float: none;
  list-style: none;
  padding: 10px 0 0 15px;
  text-align: center;
  position: relative;
  /* margin: 3px 0 0; */
}

.tabs li {
  float: left;
  display: block;
  /* margin-right: 5px; */
  font-size: 10PX !important;
  font-weight: 500;
}

.tabs input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
}

.tabs label {
  display: block;
  padding: 5px 21px;
  height: 40px;
  border-radius: 5px 5px 0 0;
  font-size: 15px;
  font-weight: normal;
  /* text-transform: uppercase; */
  background: #2b6dd4;
  color: white;
  cursor: pointer;
  position: relative;
  /* top: 4px; */
  /* -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; */
}

.tabs label:hover {
  background: #703688;
}

.tabs .tab-content {
  z-index: 2;
  display: none;
  overflow: hidden;
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  padding: 25px;
  position: absolute;
  top: 40px;
  left: 0;
  background: white;
}

.tabs [id^="tab"]:checked+label {
  top: 0;
  /* padding-top: 17px; */
  background: white;
  color: #339dd8;
  color: var(--blue);
}

.tabs [id^="tab"]:checked~[id^="tab-content"] {
  display: block;
}

p.link {
  clear: both;
  margin: 380px 0 0 15px;
}

p.link a {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
  margin: 0 0px;
  background-color: #612e76;
  transition: all 0.2s ease-in;
}

p.link a:hover {
  background-color: #522764;
}

/* multuple image */
/* * {
	box-sizing: border-box;
  } */

/* img {
	max-width: 100%;
  } */

.gallery-wrapper {
  max-width: 960px;
  width: 100%;

  margin: 0 auto;
  padding: 0 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 10px;
}

.gallery-wrapper .image-wrapper a {
  padding: 0.5em;
  display: block;

  width: 250px;
  height: 250px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 200ms ease-in-out;
}

.gallery-wrapper .image-wrapper a:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.gallery-wrapper .image-wrapper a img {
  width: 100%;
}

.gallery-lightboxes .image-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0ms ease-in-out;
}

.gallery-lightboxes .image-lightbox:target {
  opacity: 1;
  visibility: visible;
}

.gallery-lightboxes .image-lightbox:target .image-lightbox-wrapper {
  opacity: 1;
  transform: scale(1, 1) translateY(0);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper {
  transform: scale(0.95, 0.95) translateY(-30px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  opacity: 0;
  margin: 1em auto;
  max-width: 75%;
  padding: 0.5em;
  display: inline-block;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  position: relative;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close {
  width: 1.5em;
  height: 1.5em;
  background: #000;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white inset, 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -1em;
  top: -1em;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(-45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-radius: 4px 0 0 0;
  position: absolute;
  top: 50%;
  right: 100%;
  cursor: pointer;
  transform: rotate(-45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 4px 0 0;
  position: absolute;
  top: 50%;
  left: 100%;
  cursor: pointer;
  transform: rotate(45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
  margin: 0 auto;
  max-height: 70vh;
}

/* calender */
.btn {
  display: inline-block !important;
}

.btn-primary {
  position: absolute;
  right: 181px;

  top: 35px;
  opacity: 0;
}

.fc-left h2 {
  font-family: Poppins;
  color: #1f3977ed;
  margin: 0;

  font-size: 30px;
  font-weight: 700;
}

.fa {
  display: inline-block !important;
  margin-right: 0.5em;
  font-size: 20px;
}

.fc-content {
  float: right;
  /* margin-right: 30px; */
}

.fc-event {
  /* border:1px solid red !important; */
  padding: 0 !important;
  /* margin-top: 25px !important; */
  bottom: 0 !important;
}

tr:first-child>td>.fc-day-grid-event {
  margin-top: 25px !important;
}

.I_delete {
  /* float: right; */
  margin-left: 0px !important;
}

.fc-neighboringMonth {
  color: yellow;
}

.I_edit,
.I_delete {
  cursor: pointer;
  color: white;
}

.site-header {
  margin: 1.4em 28em;
  position: absolute;
  top: -4%;
  width: 41%;
}

.input-wrapper {
  background-color: #333;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.6);
  height: 48px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 50%;
}

::-webkit-input-placeholder {
  color: #fff;
}

.search-field {
  background-color: transparent;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDYxMi4wMSA2MTIuMDEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYxMi4wMSA2MTIuMDE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iX3gzNF9fNF8iPgoJCTxnPgoJCQk8cGF0aCBkPSJNNjA2LjIwOSw1NzguNzE0TDQ0OC4xOTgsNDIzLjIyOEM0ODkuNTc2LDM3OC4yNzIsNTE1LDMxOC44MTcsNTE1LDI1My4zOTNDNTE0Ljk4LDExMy40MzksMzk5LjcwNCwwLDI1Ny40OTMsMCAgICAgQzExNS4yODIsMCwwLjAwNiwxMTMuNDM5LDAuMDA2LDI1My4zOTNzMTE1LjI3NiwyNTMuMzkzLDI1Ny40ODcsMjUzLjM5M2M2MS40NDUsMCwxMTcuODAxLTIxLjI1MywxNjIuMDY4LTU2LjU4NiAgICAgbDE1OC42MjQsMTU2LjA5OWM3LjcyOSw3LjYxNCwyMC4yNzcsNy42MTQsMjguMDA2LDBDNjEzLjkzOCw1OTguNjg2LDYxMy45MzgsNTg2LjMyOCw2MDYuMjA5LDU3OC43MTR6IE0yNTcuNDkzLDQ2Ny44ICAgICBjLTEyMC4zMjYsMC0yMTcuODY5LTk1Ljk5My0yMTcuODY5LTIxNC40MDdTMTM3LjE2NywzOC45ODYsMjU3LjQ5MywzOC45ODZjMTIwLjMyNywwLDIxNy44NjksOTUuOTkzLDIxNy44NjksMjE0LjQwNyAgICAgUzM3Ny44Miw0NjcuOCwyNTcuNDkzLDQ2Ny44eiIgZmlsbD0iI0ZGRkZGRiIvPgoJCTwvZz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  background-position: 20px 14px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border: 0;
  box-sizing: border-box;
  color: #fff;

  height: 100%;
  letter-spacing: 2px;
  outline: none;
  padding: 0 10px 0 65px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
}

#calendar {
  width: 100%;
  padding: 10px;

  background: transparent;
}

.fc-event-container {
  color: black !important;
}

.fc-sun {
  color: red;
}

/* .fc-day
  {
	  color:green !important;
	  background-color: white !important;
	  font-size: 20px !important;

	} */

.fc-button {
  background: #1f3977 !important;
  color: white !important;
}

.fc-header-left .fc-header-center .fc-header-right {
  width: 100%;
  display: block;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 77px !important;
}

.fc-state-active {
  background-color: darkgrey !important;
  color: white !important;
}

.fc-header {
  border: 1px solid green !important;
}

.fc td {
  vertical-align: bottom !important;
}

.fc-other-month {
  color: darkgrey !important;
}

.fc-row tr {
  height: 20px !important;
}

.fc-toolbar,
.fc-toolbar.fc-header-toolbar {
  margin: 0px;
  padding: 5px;
}

.fc-widget-content {
  z-index: 1;

  /* overflow-y: hidden !important; */
  width: 100% !important;
  /* height:20px !important; */
}

.fc-view-container {
  border: 1px solid darkgray !important;
  border-top: none !important;
  height: auto !important;
  background-color: white !important;
}

.list-daynumber {
  border: 1px solid red !important;
}

.fc-day-number {
  width: 18px;
  text-align: left;
  position: absolute;
  top: 5px;
  font-size: 14px !important;
  font-weight: 700;
  margin-left: 5px;
}

.fc-widget-header {
  background-color: #1f3977;
  color: white;
  border: none !important;
}

.search-field:-ms-input-placeholder {
  color: #fff;
}

.search-field:placeholder-shown {
  color: #fff;
}

.search-field:focus {
  /* background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDYxMi4wMSA2MTIuMDEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYxMi4wMSA2MTIuMDE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iX3gzNF9fNF8iPgoJCTxnPgoJCQk8cGF0aCBkPSJNNjA2LjIwOSw1NzguNzE0TDQ0OC4xOTgsNDIzLjIyOEM0ODkuNTc2LDM3OC4yNzIsNTE1LDMxOC44MTcsNTE1LDI1My4zOTNDNTE0Ljk4LDExMy40MzksMzk5LjcwNCwwLDI1Ny40OTMsMCAgICAgQzExNS4yODIsMCwwLjAwNiwxMTMuNDM5LDAuMDA2LDI1My4zOTNzMTE1LjI3NiwyNTMuMzkzLDI1Ny40ODcsMjUzLjM5M2M2MS40NDUsMCwxMTcuODAxLTIxLjI1MywxNjIuMDY4LTU2LjU4NiAgICAgbDE1OC42MjQsMTU2LjA5OWM3LjcyOSw3LjYxNCwyMC4yNzcsNy42MTQsMjguMDA2LDBDNjEzLjkzOCw1OTguNjg2LDYxMy45MzgsNTg2LjMyOCw2MDYuMjA5LDU3OC43MTR6IE0yNTcuNDkzLDQ2Ny44ICAgICBjLTEyMC4zMjYsMC0yMTcuODY5LTk1Ljk5My0yMTcuODY5LTIxNC40MDdTMTM3LjE2NywzOC45ODYsMjU3LjQ5MywzOC45ODZjMTIwLjMyNywwLDIxNy44NjksOTUuOTkzLDIxNy44NjksMjE0LjQwNyAgICAgUzM3Ny44Miw0NjcuOCwyNTcuNDkzLDQ2Ny44eiIgZmlsbD0iIzAwMDAwMCIvPgoJCTwvZz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K); */
  color: #000;
}

.focus-background {
  /* background-color: #fff; */
  display: block;
  border: 1px solid red;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;
  width: 100%;
  transition: transform 0.3s;
  transform: scaleX(0);
  transform-origin: right center;
}

.search-field:focus~.focus-background {
  transform: scaleX(1);
  transform-origin: left center;
}

#calendar-popup {
  display: none;
  position: fixed !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 100;
  top: 0 !important;
  left: 0 !important;
  /* border-radius: 5PX !important; */
  width: 100% !important;
  height: 100% !important;
  background: rgb(0, 0, 0, 0.5);
}

.calander_popip_title {
  /* border-bottom: 1px solid #484141; */
  text-align: center;
  margin: 0px;
  padding: 0.5em;
  font-size: 20px;
  color: #1f3977;
  /* background: #1F3977; */
}

#calendar-popup * {
  display: block;
}

.fc-day-grid-event .fc-content {
  color: white;
}

.fc-event-title {
  background: red;
}

#calendar-popup header {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.9em;
  margin-bottom: 0.5em;
  background: #a1a1ad;
}

/* #calendar-popup button.close {
	margin: 10px 10px 0 0;
  } */

#calendar-popup label,
#calendar-popup input,
#calendar-popup textarea {
  font-size: 0.9em;
  font-weight: normal;
  margin-bottom: 0;
  padding: 0;
  font-weight: bold;
  width: 100%;
}

#calendar-popup input {
  margin-bottom: 0.5em;
  padding: 0;
}

#calendar-popup .submit_btn {
  width: 86%;

  /* background-color:var(--darkBlue) !important; */
  color: white !important;
  border: none;
  padding: 12px 20px;
  margin-top: 0;
  background: #1f3977;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-left: auto;
}

#calendar-popup time {
  font-style: italic;
  line-height: 1em;
  margin-top: 0;
}

#calendar-popup form,
#calendar-popup div#event {
  margin: 0.75em 0 0.75em 0;
  /*   padding-left: 20px; */
}

#calendar-popup li {
  /* margin-bottom: 0.33em; */
  /* padding:10px; */
  background-color: white !important;
  /* margin-right: 42px; */
}

#calendar-popup li:nth-child(odd) {
  background: white;
}

#calendar-popup li:nth-child(even) {
  background: white;
}

#calendar-popup li.location,
#calendar-popup li.details {
  font-size: 0.85em;
  color: #333;
}

#calendar-popup div.prong {
  position: relative;
  float: right;

  bottom: 0;
  right: 15px;

  height: 0;
  width: 20px;
}

#calendar-popup div.prong div.bottom-prong-dk {
  position: relative;
  top: 0;
  left: 0;

  border: 10px solid green;
  border-color: rgba(200, 200, 200, 0.75) transparent;
  border-bottom: 0;
}

#calendar-popup div.prong div.bottom-prong-lt {
  position: relative;
  top: -10px;
  left: 2px;
  width: 0;

  border: 8px solid;
  border-color: white transparent;
  border-bottom: 0;
}

#calendar-popup div.prong div.top-prong-dk {
  position: relative;
  top: 2px;
  left: 0;

  border: 10px solid;
  border-color: rgba(200, 200, 200, 0.75) transparent;
  border-top: 0;
}

#calendar-popup div.prong div.top-prong-lt {
  position: relative;
  top: -6px;
  left: 2px;
  width: 0;

  border: 8px solid;
  border-color: white transparent;
  border-top: 0;
}

.modle {
  display: none;
  position: fixed !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 100;
  top: 0 !important;
  left: 0 !important;

  width: 100% !important;
  height: 100% !important;
  background: rgb(0, 0, 0, 0.5);
}

.modle-continer {
  position: absolute;
  width: 35%;
  margin-bottom: 0;
  margin: 10px 500px;
  padding: 0 20px;
  z-index: 1000;

  border-radius: 10px;
  color: black;
  background: #fff;
  animation-name: modalopen;
  animation-duration: 1s;
}

.modal-header {
  background: #eee;
  padding: 8px;
  color: #000;
  margin-top: 10px;
}

.modal-header h2 {
  float: right;
}

.modal-header .close {
  padding: 0 !important;
  padding-right: 5PX !important;
  margin-top: -10px !important;
}

.close_button:hover {
  color: white !important;
}

.modal-title {
  line-height: 0 !important;
  font-weight: bold !important;
}

.close-btn {
  color: #000;

  font-size: 30px;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;

  padding: 0;
}

.modal-footer {
  /* background:#eee; */
  /* padding:10px; */
  color: #000;
  text-align: center;
}

.modal-footer h3 {
  margin: 0;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#search_result {
  position: absolute;
  top: 62px;
  left: 594px;
  border: 1px solid #111;
  padding: 1em 7.7em;
  display: none;
}

.autocomplete {
  background: #fff;
  position: relative;
}

.autocomplete .close {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  top: 17px;
  left: calc(100% - 50px);
  color: #000;
  cursor: pointer;
  display: none;
}

.autocomplete .close.visible {
  display: block;
}

.dialog {
  width: 51%;
  display: none;
  min-height: 40px;
  max-height: 329px;
  overflow: scroll;
  border-top: 1px solid #f4f4f4;
  z-index: 12 !important;
  margin: 6px 145px;

  position: absolute;
}

.dialog.open {
  display: block;
  background: #fff;
  z-index: 11 !important;
  color: black;
}

.dialog div {
  padding: 20px 10px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
  position: absolute;
  left: 93px;
  width: 100%;
  height: 100%;
}

.vertical-date {
  list-style: none;
  padding: 1em;
  text-align: left;
  border-bottom: 2px solid #000;
}

.list-daynumber {
  line-height: 0.5em;
  font-size: 46px;
  font-weight: 800;
  opacity: 0.9;
}

.list-monthname {
  font-size: 32px;
  opacity: 0.6;
  border-bottom: 5px solid #ff4d55;
  width: 59px;
}

.duration {
  display: block;
  color: #908383;
  font-weight: 600;
  margin-top: 0.8em;
}

.s_title {
  color: #ed8392;
  font-size: 1.3em;
  margin: -13px 34px;
  position: absolute;
  font-weight: 900;
}

.s_des {
  font-size: 16px;
  font-style: italic;
}

/* horizontal tabs */
#exTab1 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

/* remove border radius for the tab */

#exTab1 .nav-pills>li>a {
  border-radius: 0;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills>li>a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}

canvas {
  width: 400px;
  height: 200px;
  position: absolute;
  border: 3px solid #000000;
}

.addCourse {
  display: none;
}

/* ACTIVITY LOG DROPDOWN */
.pen .navbar-toggle {
  border: none;
  background: transparent !important;
}

.pen .navbar-toggle:hover {
  background: transparent !important;
}

.pen .navbar-toggle .icon-bar {
  width: 22px;
  transition: all 0.2s;
}

/* ANIMATED X */
.navbar-toggle.x .icon-bar:nth-of-type(1) {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggle.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggle.x .icon-bar:nth-of-type(3) {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
  transform: rotate(0);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
  transform: rotate(0);
}

/* END ANIMATED X */

/* ANIMATED RIGHT ARROW */
.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  transform-origin: right top;
  width: 22px;
}

.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.rarr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  transform-origin: right bottom;
  width: 22px;
}

.navbar-toggle.rarr .icon-bar:nth-of-type(1) {
  transform: translate3d(11px, 6px, 0) rotate(45deg);
  transform-origin: right top;
  width: 11px;
}

.navbar-toggle.rarr .icon-bar:nth-of-type(2) {
  /* transform: scale(0.75); */
}

.navbar-toggle.rarr .icon-bar:nth-of-type(3) {
  transform: translate3d(11px, -6px, 0) rotate(-45deg);
  transform-origin: right bottom;
  width: 11px;
}

/* END ANIMATED RIGHT ARROW */

/* ANIMATED LEFT ARROW */
.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.larr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.larr .icon-bar:nth-of-type(1) {
  transform: translate3d(0px, 6px, 0) rotate(45deg);
  transform-origin: left top;
  width: 11px;
}

.navbar-toggle.larr .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -6px, 0) rotate(-45deg);
  transform-origin: left bottom;
  width: 11px;
}

/* END ANIMATED LEFT ARROW */

/* ANIMATED UP ARROW */
.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.uarr.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
  width: 22px;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(1) {
  transform: translate3d(1px, 0, 0) rotate(-45deg);
  transform-origin: center center;
  width: 11px;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(2) {
  transform: rotate(-90deg);
  transform-origin: center center;
}

.navbar-toggle.uarr .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -14px, 0) rotate(-135deg);
  transform-origin: right top;
  width: 11px;
}

/* END ANIMATED UP ARROW */

/* ANIMATED PLUS */
.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.plus .icon-bar:nth-of-type(1) {
  transform: translate3d(0px, 6px, 0) rotate(90deg);
  transform-origin: center center;
}

.navbar-toggle.plus .icon-bar:nth-of-type(3) {
  transform: translate3d(0px, -6px, 0) rotate(-90deg);
  transform-origin: center center;
}

/* END ANIMATED PLUS */

/* ANIMATED MINUS */
.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(3) {
  transform: translate3d(0, 0, 0) rotate(0deg);
}

.navbar-toggle.minus .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 6px, 0);
}

.navbar-toggle.minus .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -6px, 0);
}

/* END ANIMATED MINUS */

/* FORCE NAVBAR-TOGGLE */

.pen .navbar-header {
  float: none;
}

.pen .navbar-toggle {
  display: block;
}

.pen .navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.pen .navbar-collapse.collapse {
  display: none !important;
}

.pen .navbar-nav {
  float: none !important;
  margin: 7.5px -15px;
}

.pen .navbar-nav>li {
  float: none;
}

.pen .navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pen .navbar-collapse.collapse.in {
  display: block !important;
}

.pen .navbar-brand {
  font-size: 14px;
}

.event-color-c {
  display: flex;
  margin: 16px;
  align-items: center;
  cursor: pointer;
}

.event-color-label {
  flex: 1 0 auto;
}

.event-color {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 240px;
  background: #5ac8fa;
}

.crud-color-row {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.crud-color-c {
  padding: 3px;
  margin: 2px;
}

.crud-color {
  position: relative;
  min-width: 46px;
  min-height: 46px;
  margin: 2px;
  cursor: pointer;
  border-radius: 23px;
  background: #5ac8fa;
}

.crud-color-c.selected,
.crud-color-c:hover {
  box-shadow: inset 0 0 0 3px #007bff;
  border-radius: 48px;
}

.crud-color:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  color: #f7f7f7;
  font-size: 20px;
  text-shadow: 0 0 3px #000;
  display: none;
}

.crud-color-c.selected .crud-color:before {
  display: block;
}

.course::-webkit-scrollbar {
  width: 5px;
}

.course::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 8px;
}

.course::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  */
  background-color: rgb(196, 193, 193);
}

.rc-tabs {
  border: none !important;
  width: 95% !important;
}

.rc-tabs-tab {
  border: 0;
  font-size: 20px;
  background: transparent !important;
  margin-right: 30px !important;
  display: flex;
  outline: none;
  cursor: pointer;
  position: relative;
  font-weight: lighter;
  align-items: center;
}

.rc-tabs-ink-bar-animated {
  background: grey !important;
  height: 2px !important;
}

.rc-tabs-tab-active {
  color: #1f3977 !important;
  font-weight: 600 !important;
}

.rc-tabs-tab-btn {
  font-weight: 500 !important;
  line-height: 32px;
  font-size: 14px !important;
  color: #1f3977 !important;
}

.addOptions {
  width: 900px !important;
  height: 25px;
  margin-top: 5px;
  border: 1px solid grey;
  background: none !important;
  font-size: 12px;
  padding-left: 5px;
}

.remove_field {
  color: black !important;
  margin-left: 5px !important;
}

/* fqa dropdown */
.styles_faq-row-wrapper__3vA1D h2 {
  color: black !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}

.faq-row {
  border: 1px solid #dbdbdd !important;
  margin-top: 3px !important;
  border-radius: 3px !important;
  padding: 0 !important;
  background-color: #eff6ff !important;
  margin-right: 10px !important;
}

.faq-title {
  margin-right: 10px !important;
}

.row-title {
  margin: 0 !important;
  font-size: 12px !important;
  color: black !important;
  margin-left: 6px !important;
  font-weight: 500 !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
  margin: 0 !important;
  font-size: 12px !important;
  color: black !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.faq-body {
  margin-top: 10px !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw svg {
  margin-top: 0px !important;
  font-size: 5px !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw {
  top: 10px !important;
  max-width: 18px !important;
  max-height: 18px !important;
}

.poi-info-window .full-width {
  width: 300px !important;
  height: 25px !important;
}

.product_item_list {
  list-style: disc !important;
}

.lecture_image:hover {
  transform: scale(1.1);
}

.calender_date_time {
  color: #1f3977;
  font-size: 12px;
}

.view_event_calendar {
  /* border-bottom:1px solid #dbdbdd; */
  width: 100%;
  font-style: normal !important;
  padding-top: 4px;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  border-radius: 5px;
}

/* attdence chart */
.donutchart {
  width: 100% !important;
  height: 100% !important;
}

text {
  font-size: 45px !important;
  font-weight: 500;
}

.Ticket_line_graph text {
  font-size: 12px !important;
}

.donutchart-legend-item-label {
  color: red !important;
}

/* bar chart for campus news */
.chart rect {
  fill: crimson;
}

select {
  color: #9e9e9e !important;
  /* margin: 5px 0px 0px 0px !important; */
  /* padding-left: 3px !important; */
  font-size: 13px !important;
  font-weight: 500;
}

/* responsive dashboard */

.container {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.table-cards .row .col-md-3 {
  margin-bottom: 0px !important;
}

.table-cards .row .col-md-6 {
  margin-bottom: 12px !important;
}

.table-cards .row .col-md-4 {
  margin-bottom: 12px !important;
}

.table-content {
  width: 100% !important;
  min-width: 474px;
  overflow-x: hidden !important;
}

@media screen and (min-width: 250px) and (max-width: 1200px) {
  .calendar-container {
    width: 100%;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 250px) and (max-width: 300px) {
  .small-box>.inner {
    padding: 2px;
  }
}

.displayTicket {
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.displayTicket::-webkit-scrollbar {
  width: 5px;
}

.displayTicket::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 8px;
}

.displayTicket::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  */
  background-color: rgb(196, 193, 193);
}

/* RESPONSIVE MODAL */
.cta {
  background-color: white;
  /* border: 1px solid lightgrey; */
  border-radius: 6px;
  /* color: tomato; */
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 700;
  /* margin-bottom: 1em; */
  /* padding: 0.3em 1em; */
  text-decoration: none;
}

.cta:hover {
  font-weight: bold;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 5 !important;
}

.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  z-index: -1;
  display: none;
}

.modaloverlay:target {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.modaloverlay .modalContainer {
  background-color: #f2f2f2;
  height: auto;
  padding-bottom: 10px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  /* padding: 3em; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modaloverlay .modalContainer::-webkit-scrollbar {
  width: 8px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 10px;
}

.modaloverlay .modalContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(248, 7, 7, 0.5);  */
  background-color: rgb(196, 193, 193);
}

@media (min-width: 500px) {
  .modaloverlay .modalContainer {
    height: auto;
    margin: 5% auto;
    max-height: 57em;
    max-width: 66em;
    width: 35%;
  }
}

.modaloverlay .modalContainer>iframe,
.modaloverlay .modalContainer>div {
  border: none;
  width: 100%;
  height: auto;
}

.modaloverlay .closeContainer {
  background-color: #e4e6eb;
  color: #525963;
  font-size: 24px;
  padding: 0px 12px;
  position: absolute;
  right: 4px;

  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  top: 4px;
  z-index: 1;
}

.modaloverlay .closeContainer:hover {
  background-color: #e4e6eb;
  color: #525963;
  font-size: 24px;
  padding: 0px 12px;
  position: absolute;
  right: 4px;

  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  top: 4px;
  z-index: 1;
}

.modaloverlay .birthday {
  background-color: #f2f2f2;
  color: black;
  width: auto !important;
  font-weight: 600;
  font-size: 24px;
  padding: 8px 12px;
  /* position: absolute; */
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
}

/* craete campus form */
@media screen and (min-width: 250px) and (max-width: 1280px) {
  .form-buttons1 {
    border-radius: 5px !important;
    background-color: rgb(41, 48, 67) !important;
    padding: 10px 10px !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }

  .form-buttons2 {
    font-weight: bold !important;
    border-radius: 5px !important;
    color: rgb(31, 57, 119) !important;
    margin-left: auto !important;
    background-color: white !important;
    padding: 10px 10px !important;
    font-size: 12px !important;
    border: 1px solid rgb(31, 57, 119) !important;
  }

  .form-buttons3 {
    font-weight: bold !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    background-color: rgb(31, 57, 119) !important;
    padding: 10px 4px !important;
    font-size: 12px !important;
  }
}

.form form input[type="text"],
input[type="email"],
input[type="password"] {
  /* margin-left: 0 !important; */
}

/* search box */
.fSQziN {
  margin-top: 20px !important;
}

.fbhklE {
  font-weight: bold !important;
}

.iAwKFK:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.dNgLTz {
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.dNgLTz:not(:last-of-type) {
  background: white !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #eff6ff !important;
}

.hCBnvI {
  border-bottom-width: 6px !important;
  border-bottom-color: #eff6ff !important;
}

.fbhklE {
  border-bottom-width: 4px !important;
  border-bottom-color: #eff6ff !important;
}

.bhoXfZ,
.bhoXfZ {
  border-top-width: 3px !important;
  border-top-color: #eff6ff !important;
}

.gZzlir {
  display: none !important;
}

.gzECfY {
  background: #eff6ff !important;
  padding: 0 !important;
  margin-bottom: 5px !important;
}

.bzFOCm {
  background: white !important;
  /* border-radius: 20px !important; */
  height: 32px !important;

  border: none !important;
  font-weight: 600 !important;
}

.fSQziN {
  overflow-x: hidden !important;
  margin-top: 0px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiCard-root {
  margin-top: 20px !important;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .fSQziN {
    /* width: 220% !important; */
  }

  /* .datatable_header
	{
		width:90% !important;
	} */
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .fSQziN {
    overflow-x: auto !important;
  }
}

.logout_account:hover {
  background: transparent !important;
}

/* RESPONSIVE UPLOAD IMAGE */
.add-img {
  margin-left: 0px;
}

.image-logo {
  width: 100px !important;
  height: 100% !important;
  margin-left: 0px;
}

@media screen and (min-width: 250px) and (max-width: 1280px) {
  .img-label {
    margin-left: 10px;
  }
}

@media screen and (min-width: 250px) and (max-width: 1280px) {
  .img-text {
    margin-left: -30px !important;
  }

  /* .img-button{
		margin-left: -28px!important;
		margin-top: 4px!important;

	} */
}

.fxzUIu {
  padding: 0px 32px 0 2px !important;
}

::-webkit-file-upload-button {
  color: #339dd8;
  font-weight: bold;
  font-size: 12px;
  background: #1f3977;
  color: white;
  padding: 5px;
  border-radius: 3px;
  border: none;
  content: "Select Image" !important;
}

.select_status {
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* start date end date */
/* .input-group input[type="text"], textarea, input[type="password"] {
  font-size: 16px;
  border: 1px solid grey;
  width: 100%;
  padding: 12px 7px;
  margin: 0 0 8px 0;
  resize: none;
  background: transparent !important;
  -webkit-appearance: none;
  border-radius: 2px;
  font-weight: 200;
} */
#blah img {
  width: 100% !important;
  height: 100% !important;
}

.flex-row {
  display: flex;
  flex: 1 1;
}

.flex-col-sm-5 {
  flex: 1 1;
  padding: 0;
}

.form-field-wrapper {
  margin: 0 0 20px 0;
}

span.input-group-addon {
  background: transparent;
  position: absolute;
  border: 0;
  top: 41%;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: right;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input-group.date .input-group-addon {
  margin-top: 15px !important;
}

/* pagination */
.MuiSvgIcon-fontSizeSmall {
  font-size: 2.25rem !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;

}

.MTableToolbar-root-5 {
  background-color: #eff6ff !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid #eff6ff !important;
  letter-spacing: 0 !important;
}

#main_cal .calendar table td,
th {
  border: none !important;
}

/* .dataTables_wrapper .dataTables_filter input
{
  background:white !important;
   height:32px !important;
   width:22% !important;
   border:none !important;
   font-weight:400 !important;
   border-radius:30PX !important;

   color: darkgrey !important;
   position: absolute !important;
   top: -100px !important;
   right:0 !important;

}
.dataTables_wrapper .dataTables_filter label{
  color: transparent !important ;
}
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate
{
  color: rgba(0,0,0,.8) !important;
}
.dataTables_length select
{
  border: none !important;

  margin-left: -7px !important;
  margin-right: -5PX !important;
  background-color: transparent !important;
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400 !important;
}
.dataTables_length label
{
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400 !important;
}
.dataTables_info
{
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400 !important;

}
.table.dataTable.no-footer
{
  border-bottom: none !important;
}
.teacher_rows tr{
  background-color: white !important;
}
.dataTables_wrapper .dataTables_filter
{
  margin: 10px !important;

}
.paginate_button
{
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400 !important;
}
.paginate_button .disabled :hover
{
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400 !important;
  border:1px solid red !important;
} */

/* dashboard calendar */
/* / appointment table status button / */
.status_button {
  border: none;
  padding: 3px;
  margin-top: 0;
  /* / background:#1F3977; / */
  flex-wrap: wrap;
  border-radius: 5px;
  width: 80px;
  text-align: center;
}

.status_text {
  font-size: 12.25PX;
  font-weight: 400;
  font-family: Poppins;
  text-align: center;
  color: white;
}

.CalendarDay__highlighted_calendar:not(.CalendarDay__selected) {
  background-color: #f7f8f6 !important;
}

.CalendarMonth_table .td {

  width: 30px !important;
  height: 30px !important;
}

.CalendarDay__outside {
  color: white !important;
  border: 1px solid #e4e7e7 !important;
}

.DayPickerNavigation_button__default {
  border: none !important;
}

.DayPickerNavigation_svg__horizontal {
  display: none !important;
}

.CalendarMonth_caption strong {
  display: none !important;
}

.CalendarMonth_table {
  margin-top: -55PX !important;
  margin-left: -10px !important;
  margin-right: 15px !important;
  border-collapse: separate !important;
  border-spacing: 5px !important;

  /* / border:1px solid darkgrey; / */
}

.DayPicker_transitionContainer {

  height: 240px !important;
}

.DayPicker_weekHeader {
  position: relative !important;
  top: 0 !important;
  padding: 0px !important;
  width: 100% !important;
  /* / border:1px solid red !important; / */
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 10px !important;
  width: auto !important;
  /* / border: 1px solid darkgrey !important; / */
}

.DayPicker_weekHeader_li {
  width: 55px !important;
  /* / border:1px solid green !important; / */
  margin: 0 !important;
}

.DayPicker_weekHeader_ul {
  padding: 0 !important;
}

.DayPicker_weekHeader_li small {
  color: black !important;
  font-size: 12px !important;
  font-weight: bold !important;

}

.DayPicker__withBorder {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: none !important;


  border-radius: 3px;
}

.DayPicker__withBorder div {
  width: auto !important;

}

.CalendarDay__default {
  width: 50px !important;
  height: 45px !important;
  border: none !important;
  margin-top: 5px !important;
  /* / border:1px solid green !important; / */
}

.CalendarDay__weekend {
  color: red !important;
}

.sc-gKclnd div {
  /* / border: 1px solid black !important; / */
  width: 100% !important;
}

.CalendarMonthGrid {
  height: 100px !important;
}

.DayPickerNavigation_button__horizontalDefault {
  display: none !important;
}

.eCUcCv:hover {
  color: white !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: none !important;

}


.datewise_events::-webkit-scrollbar {
  width: 10px;
}

.datewise_events::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

.datewise_events::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

#file-ip-1-preview {
  height: 77px;

  width: 100%;
  object-fit: contain;
}

.file-ip-1 {
  /* width: 100%;

  border: 1px solid #ced4da;
  padding: 10px; */

}

#comp_logo {
  height: 81px;
  width: 100%;
  object-fit: contain;
}

.fDquJO {
  overflow: auto !important;
}

.WQNxq {
  overflow: auto !important;
}

.WQNxq::-webkit-scrollbar {
  width: 5px;
}

.WQNxq::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.WQNxq::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.fDquJO::-webkit-scrollbar {
  width: 5px;
}

.fDquJO::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.fDquJO::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: grey;
}

.login_empty_div {
  width: 35%;
  background: #fff;
}

.login_img_div {
  width: 100%;
  padding: 100px 142px 93px 490px;
  background: rgb(255, 255, 255);
}

.login_main_div {
  height: 500px;
  width: 345px;
  position: absolute;
  top: 80px;
  left: 160px;
  border-top: 5px solid rgb(45, 93, 208);
}

.vertical-line {
  height: 100%;
  margin-right:10px;
}




/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

/*
	##Device = Desktops
	##Screen = 1281px to higher resolution desktops
         */

@media (min-width: 1281px) {

  /* CSS */

}

/*
          ##Device = Laptops, Desktops
          ##Screen = B/w 1025px to 1280px
                 */

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/*
          ##Device = Tablets, Ipads (portrait)
          ##Screen = B/w 768px to 1024px
                 */

@media (min-width: 768px) and (max-width: 1024px) {

  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
          ##Device = Tablets, Ipads (landscape)
          ##Screen = B/w 768px to 1024px
                 */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
          ##Device = Low Resolution Tablets, Mobiles (Landscape)
          ##Screen = B/w 481px to 767px
                 */

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

}

/*
          ##Device = Most of the Smartphones Mobiles (Portrait)
          ##Screen = B/w 320px to 479px
                 */

@media (min-width: 320px) and (max-width: 480px) {
  .login_img_div {
    display: none;
  }

  .login_empty_div {
    display: none;
  }

  .login_main_div {
    border-top: 5px solid rgb(45, 93, 208);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


/**
         * Makes our code later a bit neater
         **/
@mixin grad($hex) {
  background: linear-gradient(to right,
      rgba($hex, 0) 0%,
      rgba($hex, 1) 30%,
      rgba($hex, 1) 50%,
      rgba($hex, 1) 70%,
      rgba($hex, 0) 100%);
}

/**
                  * Space out the bars
                  **/
*,
*:before,
*:after {
  box-sizing: border-box;
}

aside {
  font-size: 13px;
  line-height: 24px;
  font-family: monospace;
  color: #555;
  /* text-align: center; */
}

/* main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #ECB7FE;
  padding: 24px 0;
  min-height: 250px;
} */

/**
                  * Styling the loader bar
                  **/
.loader {
  width: 100%;
  height: 5px;
  overflow: hidden;
  background: #ECB7FE;
  border-radius: 10px;

  &::after {
    content: " ";
    height: 100%;
    display: block;
    transform-origin: top left;
  }
}

/**
                  * Full width bars, alternating colours
                  **/
.loader--full {
  animation: solidcolours 3.75s steps(1) infinite 0.75s;

  &:after {
    width: 100vw;
    animation: solidcolours 3.75s steps(1) infinite, load 0.75s infinite linear;
  }
}

/**
                  * Bars 1/3 width, either solid or with gradiated colour
                  **/
.loader--solid {
  &:after {
    width: 6vw;
    animation: solidcolours 3s steps(1) infinite, loadthird 1s infinite linear;
  }
}



/** * Javascript overwrite to stop animations**/
.loader.loader--paused {
  animation: 0;

  &:after {
    animation: 0;
  }
}

/**
                  * The animations.
                  **/
@keyframes load {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loadthird {
  0% {
    transform: translateX(-33.3vw);
  }

  100% {
    transform: translateX(100vw);
  }
}

/* there are 5 frames. we've given an animation duration of 5s so each frame gets 1s of time, matching the length of our load animation */
@keyframes solidcolours {
  0% {
    background-color: #AC48C5;
  }

  20% {
    background-color: #AC48C5;
  }

  40% {
    background-color: #AC48C5;
  }

  60% {
    background-color: #AC48C5;
  }

  80% {
    background-color: #AC48C5;
  }
}


.flagged_inner {
	padding: 10px !important;
}
.all_icon_imgs_div {
	display: flex;
	align-items: center;
	width: 45px;
  height: 45px;
	justify-content: center;
	border-radius: 3px;
}

.all_icon_imgs {
	height: 20px;
	width: 20px;
	font-family: Poppins;
}
.nine_font_class {
	font-size: 9px;
	font-weight: 500 !important;
	font-family: Poppins
}

.ten_font_class {
	font-size: 10px;
	font-weight: 500 !important;
	font-family: Poppins
}

.eleven_font_class {
	font-size: 11px !important;
	font-weight: 500 !important;
	font-family: Poppins !important
}

.twelve_font_class {
	font-size: 12px;
	font-weight: 600;
	font-family: Poppins
}

.sixteen_font_class {
	font-size: 16px;
	font-weight: 600;
	font-family: Poppins
}
.box_border_class {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px;
	border-radius: 3px;
}
.all_action_buttons {
	background: rgb(71, 121, 240);
	color: white;
	border-radius: 5px;
	font-size: 9px;
	font-weight: 600;
	border: none;
	height: 24px;
	width: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.action_buttons_end_css {
	display: flex;
	justify-content: end;
	position: relative;
	width: 100%;
	cursor: pointer;
}
.flag_inner_div {
	margin-left: 15px;
	font-size: 10px;
	font-weight: 600;
}
.navbar{
  height: 45px;
}
.go2072408551 {
	min-width: 25%;
	font-size: 11px;
	font-weight: 500;
	padding: 4px 10px;
}
.go3958317564 {
	justify-content: left !important;
}
.sc-lbOyJj{
	display: flex;
	justify-content: center;
}
.desc_class * {
	font-size: 11px !important;
	font-family: 'Poppins' !important;
	color: black !important;
	/* background: white !important; */
}
.job_desc_limit {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	min-height: 65px;
}

.job_desc_seemore_btn {
	border: none;
	background: none;
	color: blue;
	justify-content: end;
	display: flex;
	width: 100%;
}

.show-full-content {
	display: block;

}
p{
  margin: 0px;
}
.rdt_TableHeadRow{
  min-height: 35px !important;
}
.rdt_TableBody {
	min-height: 62vh;
	overflow: auto;
}
.gwAfeM{
  max-height: 70vh !important;
}

.job_logo_img {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	object-fit: contain;
}
.all_action_buttons {
	background: rgb(71, 121, 240);
	color: white;
	border-radius: 5px;
	font-size: 9px;
	font-weight: 600;
	border: none;
	height: 24px;
	width: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.card_inner_hr_css {
	margin: 10px 0px;
	color: #D9D9D9;
  border-top: 2px solid #D9D9D9;
}
.card_inner_div {
	background: #fff;
	border-radius: 5px;
	padding: 15px 20px;
	box-shadow: rgba(0, 0, 0, 0.125) 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 1px 3px;
	width: 60%;
	min-height: 540px
}
.img_hover_class{
	height: 140px;
	width: 200px;
	transition: transform 0.2s ease;
}
.overflow_class {
	width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}




.job_Content::-webkit-scrollbar {
	width: 4px;
}


.job_Content::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.job_Content::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	background-color: grey;
}




.note-btn-group .btn{
           background-color: #fff !important;
           margin-top: 0px !important;
       }
       .note-btn-group .note-btn{
         border-color: rgba(0,0,0,.2) !important;
         padding: 0.2rem 0.65rem !important;
         font-size: 9px !important;
       
       }
       .btn{
         display: inline-block !important;
           font-weight: 400 !important;
           color: #212529 !important;
           text-align: center !important;
           vertical-align: middle !important;
           -webkit-user-select: none !important;
           -ms-user-select: none !important;
               user-select: none !important;
           background-color: transparent !important;
           border: 1px solid transparent !important;
           padding: 0.375rem 0.75rem !important;
           font-size: 1rem !important;
           line-height: 1.5 !important;
           border-radius: 0.25rem !important;
           letter-spacing: 0.025rem !important;
           transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
       }
       .btn-group, .btn-group-vertical{
           position: relative !important;
           display: inline-flex;
           vertical-align: middle !important;
       }
       .note-editor .note-toolbar>.note-btn-group, .note-popover .popover-content>.note-btn-group{
         
           margin-top: 5px !important;
           margin-left: 0 !important;
           margin-right: 5px !important;
       }
       .note-btn-group btn-group note-insert{
         display: none !important;
       }
       .note-style{
         display: none !important;
       }
       .panel{
         margin-bottom: 0px !important;
       }
       .btn-group, .btn-group-vertical{
           position: relative !important;
           display: inline-flex;
           vertical-align: middle !important;
       }
       .note-editor .note-toolbar, .note-popover .popover-content{
           margin: 0 !important;
           padding: 0 0 5px 5px !important;
       }
       .panel-default>.panel-heading {
           color: #333 !important;
           background-color: #f5f5f5 !important;
           border-color: #ddd !important;
       
       }
       .note-toolbar{
           text-align: left !important;
           background: white !important;
           position: relative !important;
           border-top-left-radius: 3px !important;
           border-top-right-radius: 3px !important;
       }
       .note-editor .note-dropzone{
           position: absolute !important;
           display: none !important;
           z-index: 100 !important;
           color: #fa87a2 !important;
           background-color: #fff !important;
           opacity: .95 !important;
       }
       .note-editor.note-frame{
           color: black !important;
           background-color: white!important;
       }
       .note-editor {
           position: relative !important;
       }
       .note-dropdown-menu dropdown-menu dropdown-style{
           position: absolute;
           will-change: transform;
           top: 0px;
           left: 0px;
           transform: translate3d(0px) !important;
       }
       .dropdown-menu{
           position: absolute;
           top: 100%;
           left: 0;
           z-index: 1000;
           display: none;
           float: left;
           min-width: 10rem;
           padding: 0.5rem 0;
           margin: 0.125rem 0 0;
           font-size: 1rem;
           color: #212529;
           text-align: left;
           list-style: none;
           background-color: #fff;
           background-clip: padding-box;
           border: 1px solid rgba(0, 0, 0, 0.15);
           border-radius: 0.25rem;
       }
       .note-editor.note-airframe .note-editing-area .note-editable,
        .note-editor.note-frame .note-editing-area .note-editable{
         padding: 5px;
        }
       
       .note-editable{
         height: 110px;
         font-size: 11px;
         width: 100%;
         outline: none;
         overflow: auto;
       }
       
       .note-editable::-webkit-scrollbar {
                  width: 2px;
       }
       
       .note-editable::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  border-radius: 10px;
       
       }
       
       .note-editable::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                  background-color: grey;
       }
       
       .panel-default>.panel-heading{display: none !important}
       .note-codable{display: none !important}
       .note-status-output{ display: none !important}
       
       
       
       
       .note-toolbar {
           /* text-align: left !important;
           background: rgb(103, 50, 226) !important; */
         }
       
         .btn-group .btn {
           /* background-color: #9eed0c !important;
           margin-top: 0px; */
         }
         /* .btn-group .btn:hover{
           box-shadow: none !important;
          
           border-radius: 5px !important;
           width: auto !important;
           height: auto !important;
         } */
       
         /* .btn-group > .btn:hover, .btn-group-vertical > .btn:hover{
           z-index: 99999;
         } */
         .note-editor.note-frame, .note-editor.note-airframe{
           /* margin: 0px !important; */
         }
       
       
         .note-editor.card:after {
           content: "";
           /* display: block; */
           /* position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 200%; */
           /* pointer-events: none; */
           /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%); */
           /* transform: translateY(-50%); */
           /* transition: transform calc(var(--d) * 2) var(--e); */
         }
       
         .note-editor .note-airframe .card {
           /* position: relative;
           display: flex;
           align-items: flex-end;
           overflow: hidden;
          
           height: auto;
           width: 100%;
           text-align: center;
           color: #000 !important;
           background-color: #fff !important; */
       
           /* color: whitesmoke; */
           /* background-color: whitesmoke; */
           /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1); */
         }
       
         .note-editor.note-frame {
           /* color: #ffffff !important;
           background-color: white !important; */
         }
       
       
       
         .note-editing-area.card {
           /* position: relative;
           display: flex;
           align-items: flex-end;
           overflow: hidden;
           
           height: auto;
           width: 100%;
           text-align: center;
           
           background-color: whitesmoke; */
           
         }
       
       
         .note-editor.note-frame {
           color: black !important;
           border-radius: 3px;
          
         }
